<template>
      <div class="rounded bg-white p-3 xs-p-2 search-form" :class="{'mobile': is_mobile}">
          <h2 v-if="is_mobile" class="text-white px-2 fw-light">{{trans.search_panel}}</h2>
          <div v-show="!success && loaded">
              <!-- {{-- Form --}} -->
                <Form
                    :urls="urls"
                    :lang="lang"
                    :filters_query_string="filters_query_string"
                    :init_data="init_data"
                    @loaded="(val) => $emit('loaded', val)"
                    @success="(val) => $emit('success', val)"
                    @formResponse="(val) => $emit('formResponse', val)"
                    @formResponseURL="(val) => $emit('formResponseURL', val)"
                    @error="handleError"
                />
             </div>
          <!-- {{-- Loading --}} -->
          <Loader v-if="!loaded"/>

          <!-- <div v-if="success && Object.keys(formResponse).length > 0" class="row">
              {{-- Success message--}}
          </div> -->

          <!-- {{-- Request Server Error --}} -->
          <div v-else-if="errors && errors.request" class="text-danger pt-2">
              {{ errors.request }}
          </div>
        </div>
</template>

<script>
import indexMixin from "@/mixins/indexMixin";
import Form from "./Form";
import Vue from 'vue'

export default {
    name: 'search',
    mixins: [indexMixin],
    components:{
        Form
    },
    props: {
        is_mobile: {
            type: Boolean,
            default: false
        },
        urls: {
            type: Object,
            required: true
        },
        lang: {
            type: String,
            default: 'en'
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        },
        filters_query_string: {
            type: String,
            default: ''
        },
        init_data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
          
        }
    },
    created(){
        if (!Vue.prototype.$trans) {
            Vue.prototype.$trans = this.trans 
        }     
    }
}


</script>