<template>
  <div v-if="breakpoint === 'lg' || breakpoint === 'xl'">
      <Order
            :rooms="rooms"
            :final_amounts="finalAmounts"
            :order_query_string="orderQueryString"
            :urls="urls"
            :lang="lang"
            :details="details"
            :is_valid="isOrderValid"
            :trans="trans"
            :init_data="init_data"
            :init_details="init_details"
        />
    </div>
    <!-- Mobile Order -->
    <div v-else>
        <div class="p-2 fixed-bottom position-fixed border border-2 bg-white" :class="!isOrderValid ? 'border-warning' : 'border-success'">
            <div class="container">
                <div class="d-flex align-items-center justify-content-between" data-bs-toggle="offcanvas" data-bs-target="#filtersMenu" aria-controls="filtersMenu">
                    <div v-if="isOrderValid" class="btn btn-success text-white">
                        {{trans.book}}
                    </div>
                    <div v-else class="btn btn-warning">
                        {{trans.new_search ? trans.new_search : 'Ново търсене'}}
                    </div>
                    <div v-if="Object.keys(details.rooms).length > 0" class="d-flex justify-content-between">
                        <strong>{{trans.total}}</strong>
                        <div class="d-flex align-items-end justify-content-end  lh-1 ">
                            <strong class="fs-3 mx-1">{{finalAmounts.spo_price}}{{details.currency}}</strong>
                        </div>   
                        <!-- <i class="align-self-center fas fa-chevron-up"></i>      -->
                    </div> 
                </div>
            </div>
        </div>
        <div class="offcanvas offcanvas-bottom bg-white" tabindex="-1" id="filtersMenu" aria-labelledby="filtersMenuLabel">
            <div class="offcanvas-header d-flex justify-content-end xs-p-2">
                <button id="filtersMenuClose" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div id="offcanvasBody" class="offcanvas-body text-black text-center bg-gray-2 xs-p-0">
                <div class="d-inline-block text-start">
                    <!-- Order -->
                    <Order
                        :rooms="rooms"
                        :final_amounts="finalAmounts"
                        :order_query_string="orderQueryString"
                        :urls="urls"
                        :lang="lang"
                        :details="details"
                        :is_valid="isOrderValid"
                        :trans="trans"
                        :init_data="init_data"
                    />
                </div>
            </div>
        </div>      
    </div>
</template>

<script>
import {useMediaQuery} from "@/components/common/helper"

import Order from "./Order.vue";

export default {
    name: 'OrderIndex',
    components: {
        Order
    },
    props: {
        init_data: {
            type: Object,
            required: true,
        },
        rooms: {
            type: Object
        },
        urls: {
            type: Object,
            required: true
        },
        lang: {
            type: String,
            default: 'en'
        },
        details: {
            type: Object,
            required: true
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        },
        final_amounts: {
            type: Object,
            default: () => {
                return {}
            }
        },
        order_query_string: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            breakpoint: 'lg',
        }
    },
    methods: {
        useMediaQuery
    },
    computed: {
        finalAmounts() {
            let reg_price = 0
            let spo_price = 0
            if (this.rooms) {
                Object.keys(this.rooms).map(key => {
                    reg_price += this.rooms[key].reg_price
                    spo_price += this.rooms[key].spo_price
                })
            }

            return {
                spo_price,
                reg_price
            }
        },
        orderQueryString(){
            // Genetare query string
            if (this.rooms && Object.keys(this.rooms).length > 0) {
                let queryString = '?'
        
                for (var key in this.rooms) {
                     console.log(this.rooms[key])
                     queryString += `${key}=${JSON.stringify(this.rooms[key])}&`
                }

                console.log(queryString)

                queryString += `final_amounts=${JSON.stringify(this.finalAmounts)}&`

                // Get 'start' 'end' dates from the current url and add values to the new query string
                const params = new URLSearchParams(location.search);
                queryString += `start=${params.get('start')}&end=${params.get('end')}&`

                return queryString
            }

            return null
            
        },
        isOrderValid(){
            if (this.rooms && this.details) {
                return Object.keys(this.rooms).length === Object.keys(this.details.rooms).length
            }

            return false
        },
    },
    mounted(){
        useMediaQuery((size) => this.breakpoint = size)
    },
}
</script>

<style scoped>
   /* .offcanvas-header{
    background: transparent;
   }
   #filtersMenuClose{
    background-color: #fff;
    border: 1px solid #ccc;
   } */
</style>