// source:  https://logaretm.github.io/vee-validate/

import { extend, localize } from 'vee-validate';
import { required, min, oneOf, email, numeric, regex } from 'vee-validate/dist/rules';
import en from './i18n/validations/en.json';
import bg from './i18n/validations/bg.json';

let locales = {
    en: en,
    bg: bg
}

const appLocale = document.querySelector('html').getAttribute('lang') || 'en';
localize(appLocale, locales[appLocale]);

// Add rules
extend('email', email);
extend('required', required);
extend('numeric', numeric);
extend("regex", regex);


// Change rules
extend('oneOf', {
    ...oneOf,
    message: 'Please select one of the available values'
});

extend('min', {
    ...min,
    params: ['length', 'name'],
    // message: 'The {name} must be at least {length} characters.',
});

// Custom rules
extend('reqFalse', {
    validate: value => {
        return value === 0 || value === false;
    },
    message: 'This field must be unchecked.'
});

extend('reqTrue', {
    validate: value => {
        return value === 1 || value === true;
    }
});