<template>
    <div>
        <div id="singleMapContainer" ref="singleMap"></div>
    </div>
</template>

<script>
import image from "../../assets/images/vacantina_pin.png"

export default {
   name: 'SingeMap',
   props:{
       lat: {
           default: 0
       },
       lng: {
           default: 0
       }
   },
   data() {
       return {
       }
   },
    methods: {
        initMap() {
            this.map = new google.maps.Map(this.$refs["singleMap"], {
                zoom: 14,
                icon: image,
                center: new google.maps.LatLng(Number(this.lat), Number(this.lng)),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                      {
                        "featureType": "poi.business",
                        "elementType": "all",
                        "stylers": [
                          {
                              "visibility": "off"
                          }
                        ]
                      }
                  ]
            });

            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(Number(this.lat), Number(this.lng)),
                icon: image,
                map: this.map,
            });
        },
    },
    mounted(){
        this.initMap()
    }
}
</script>

<style>
  #singleMapContainer{
      min-height: 350px;
  }
</style>