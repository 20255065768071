<template>
        <div class="mt-3 stars-filter">
            <div class="rounded bg-light px-2 py-3">
                <span class="fw-bold px-2 fs-5">{{title}}</span>
                <div class="d-flex text-center mt-2 fs-2 text-gray-1">
                    <div class="flex-1 mx-2">
                        <input type="checkbox" name="stars_1" id="stars_1" hidden>
                        <label for="stars_1">
                            <i
                                class="
                                    fas
                                    fa-star
                                    cursor-pointer
                                    hover-text-warning"
                                :class="{'text-warning': !!fields['5'] || !!fields['4'] || !!fields['3'] || !!fields['2'] || !!fields['1']}"
                                @click="() => fields = {1:1, 2:0, 3:0, 4:0, 5:0}"    
                            ></i>
                        </label>
                    </div>
                    <div class="flex-1 mx-2">
                        <input type="checkbox" name="stars_2" id="stars_2" hidden>
                        <label for="stars_2">
                            <i
                                class="
                                    fas
                                    fa-star
                                    cursor-pointer
                                    hover-text-warning"
                                :class="{'text-warning': !!fields['5'] || !!fields['4'] || !!fields['3'] || !!fields['2']}"
                                @click="() => fields = {2:1, 3:0, 4:0, 5:0}"    
                            ></i>
                        </label>
                    </div>
                    <div class="flex-1 mx-2">
                        <input type="checkbox" name="stars_3" id="stars_3" hidden>
                        <label for="stars_3">
                            <i
                                class="
                                    fas
                                    fa-star
                                    cursor-pointer
                                    hover-text-warning"
                                :class="{'text-warning': !!fields['5'] || !!fields['4'] || !!fields['3']}"
                                @click="() => fields = {3:1, 4:0, 5:0}"    
                            ></i>
                        </label>
                    </div>
                    <div class="flex-1 mx-2">
                        <input type="checkbox" name="stars_4" id="stars_4" hidden>
                        <label for="stars_4">
                            <i
                                class="
                                    fas
                                    fa-star
                                    cursor-pointer
                                    hover-text-warning"
                                :class="{'text-warning': !!fields['5'] || !!fields['4']}"
                                @click="() => fields = {3:0, 4:1, 5:0}" 
                            ></i>
                        </label>
                    </div>
                    <div class="flex-1 ms-2">
                        <input type="checkbox" name="stars_5" id="stars_5" hidden>
                        <label for="stars_5">
                            <i
                                class="
                                    fas
                                    fa-star
                                    cursor-pointer
                                    hover-text-warning"
                                :class="{'text-warning': !!fields['5']}" 
                                @click="() => fields = {3:0, 4:0, 5:1}" 
                            ></i>
                        </label>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import Checkbox from "@/components/common/fields/CheckboxField.vue";

export default {
    name: "StarsFilter",
    components: {
        Checkbox
    },
    props: {
       fieldsData:{
           type: Array,
           required: true
       },
       title: {
           type: String,
           default: 'Filter'
       },
       category: {
           type: String,
           required: true
       },
        prefix: {
           type: String,
           default: ''
       }  
    },
    data() {
        return{
            //map the fieldsData array item to key:value object 
            fields: this.fieldsData.reduce((ac, {['key']:x,value}) => (ac[x] = value,ac), {})
        }
    },
    watch: {
        fields: {
            deep: true,
            handler(val){
                this.$emit('filterUpdate', Object.assign({}, {[this.category]: val}))
            }
        },
        fieldsData: {
            deep: true,
            handler(val){
                this.fields = val.reduce((ac, {['key']:x,value}) => (ac[x] = value,ac), {})
            }
        }
    },
};
</script>

<style>
</style>