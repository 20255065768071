<template>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(submitForm)" accept-charset="UTF-8">
            <div class="d-block flex-wrap d-lg-flex">
                <!-- Tabs  -->
                <div class="flex-1 m-2 min-width-0">
                    <PaymentTabs
                        :init_data="init_data"
                        :final_amounts="finalAmounts"
                        :trans="trans"
                        :urls="urls"
                        @fieldsUpdate="(val) => handleFieldsUpdate(val)"
                    />
                </div>
                <!-- Order -->
                <!-- Hide the Order on step-3 -->
                <div v-if="init_data.step !== 3"> 
                    <Order v-if="breakpoint === 'lg' || breakpoint === 'xl'"
                        :init_data="init_data"
                        :final_amounts="finalAmounts"
                        :invalid="invalid"
                        :hideButton="fields.payment_method === 'debit_credit_card' && init_data.step === 2"
                        :order_query_string="orderQueryString"
                        :urls="urls"
                        :trans="trans"
                        @fieldsUpdate="(val) => handleFieldsUpdate(val)"
                    />


                    <!-- // TODO: Mobile version  -->
                    <!-- Mobile Order -->
                    <div v-else>
                        <div class="p-2 fixed-bottom position-fixed border border-2 bg-white" :class="invalid ? 'border-warning' : 'border-success'">
                            <div class="container">
                                <div class="d-flex align-items-center justify-content-between" data-bs-toggle="offcanvas" data-bs-target="#filtersMenu" aria-controls="filtersMenu">
                                    <div v-if="fields.payment_method === 'debit_credit_card' && init_data.step === 2"></div>
                                    <div v-else>
                                        <div v-if="!invalid" class="btn btn-success text-white">
                                            {{trans.continue ? trans.continue : 'Continue'}}
                                        </div>
                                        <div v-else class="btn btn-warning">
                                            {{trans.fill_the_form ? trans.fill_the_form : 'Fill the form'}}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <strong>{{trans.total}}:</strong>
                                        <div class="d-flex align-items-end justify-content-end  lh-1 ">
                                            <strong class="fs-3 mx-1">{{finalAmounts.amount}}{{init_data.currency}}</strong>
                                        </div>
                                    <!-- <i class="align-self-center fas fa-chevron-up"></i> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="offcanvas offcanvas-bottom bg-white" tabindex="-1" id="filtersMenu" aria-labelledby="filtersMenuLabel">
                            <div class="offcanvas-header d-flex justify-content-end xs-p-2">
                                <button id="filtersMenuClose" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div id="offcanvasBody" class="offcanvas-body text-black text-center bg-gray-2 xs-p-0">
                                <div class="d-inline-block text-start">
                                    <Order
                                        :init_data="init_data"
                                        :final_amounts="finalAmounts"
                                        :invalid="invalid"
                                        :hideButton="fields.payment_method === 'debit_credit_card' && init_data.step === 2"
                                        :order_query_string="orderQueryString"
                                        :urls="urls"
                                        :trans="trans"
                                        @fieldsUpdate="(val) => handleFieldsUpdate(val)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </form>
	</ValidationObserver>
</template>

<script>

import Vue from "vue";

import formControlledMixin from '@/mixins/formMixin';

import {isObject, useMediaQuery} from "@/components/common/helper"
import PaymentTabs from "./Tabs/Index.vue";
import Order from "./Order.vue";

export default {
    name: "HotelPaymentPage",
        mixins: [
            formControlledMixin
        ],
    components: {
        PaymentTabs,
        Order
    },
    props: {
        init_data: {
            type: Object,
            // required: true,
            default: () => ({})
        },
        urls: {
            type: Object,
            required: true,
        },
        lang: {
            type: String,
            default: 'en'
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            fields: {
                full_deposit: 1,
                payment_method: 'debit_credit_card',
            },
            breakpoint: 'lg'
        };
    },
    methods: {
        handleFieldsUpdate(val) {
            this.fields = {...this.fields, ...val}
        },
        useMediaQuery
    },
    computed: {
        finalAmounts() {
            let amount = this.init_data.amount
            let percent_of_amount = Number(((this.init_data.discout * amount) / 100).toFixed(0))

            if (!this.fields.full_deposit) {
                amount = percent_of_amount
            }

            return {
                amount,
                percent_of_amount
            }
        },
        orderQueryString(){
            // Genetare query string
            if (this.fields && Object.keys(this.fields).length > 0) {
                let queryString = '?'

                for (var key in this.fields) {
                    if(isObject(this.fields[key])) {
                        // Adding objects' details
                        queryString += `${key}=${JSON.stringify(this.fields[key])}&`

                    } else{
                        queryString += `${key}=${this.fields[key]}&`
                    }
                }

                queryString += `final_amounts=${JSON.stringify(this.finalAmounts)}&`
                queryString += `step=${this.init_data.step + 1}&`
                queryString += `booking=${this.init_data.booking}`

                return queryString
            }

            return null
        }
    },
    watch:{
      // Remove the 'bank_transfer' fields from the request if not checked 'bank_transfer' payment_method
        'fields.payment_method': {
            handler(val){
              if (val === 'debit_credit_card') {
                 Vue.delete(this.fields, 'bank_transfer')
                 Vue.set(this.fields, 'full_deposit', this.init_data.full_deposit)
                //  this.initMyPos('embeddedCheckout')
              } else {
                  Vue.set(this.fields, 'bank_transfer', {
                    payment_confirmation: 0,
                })
              }
            }
        },

        // Remove the 'invoice_fields' from the request if not checked 'invoice_confirmation'
        'fields.invoice_confirmation': {
            handler(val){
                if (!val) {
                    Vue.delete(this.fields, 'invoice_fields')
                } else {
                    Vue.set(this.fields, 'invoice_fields', {})
                }
            }
        },
    },
    mounted(){
        useMediaQuery((size) => this.breakpoint = size)
    },
};

</script>
