<template>
    <ValidationProvider tag="div" :ref="providerRef" :rules="rules" :vid="vid" v-slot="v" :immediate="immediate">
        <DatePicker
            v-model="currentValue"
            :masks="masks"
            :step="1"
            :locale="locale"
            :first-day-of-week="2"
            class="custom-calendar"
            :select-attribute="attribute"
            :drag-attribute="attribute"
            :attributes="attributes"
            :popover="{
                visibility: 'click',
                keepVisibleOnInput: false,
                placement: 'bottom',
            }"
            ref="calendar"
            :model-config="modelConfig"
            @popoverWillShow="handlePopoverWillShow()"
            @popoverDidHide="handlePopoverDidHide()"
        >
            <!-- :model-config="modelConfig" -->
            <!-- @input="() => $emit('datepickerChange', range)" -->
            <!-- :popover-keep-visible-on-input="true" -->
            <!-- @input="onDateRangeChange"  -->

            <template v-slot="{ inputValue, inputEvents }">
                <input :class="fieldClass" :placeholder="placeholder" :value="inputValue" v-on="inputEvents" />
            </template>
        </DatePicker>
    </ValidationProvider>
</template>

<script>
import { add, format } from "date-fns";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { ValidationProvider } from 'vee-validate';

export default {
    name: "Datepicker",
    components: {
        ValidationProvider,
        DatePicker
    },
    props: {
        lang: {
            type: String,
            default: "en",
        },
        value: {
            type: [String, Number],
            default: "",
        },
        rules: {
            type: [String, Object],
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        vid: {
            type: String,
            default: undefined,
        },
        providerRef: {
            type: String,
            default: undefined,
        },
        type: {
            type: String,
            default: "text",
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        serverErrors: {
            type: [String, Object],
            default: undefined,
        },
        fieldClass: {
            type: String,
            default: "custom-input",
        },
        fieldID: {
            type: String,
            default: undefined,
        },
        immediate: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            locale: this.lang,
            range: {
                start: format(add(new Date(), { days: 1 }), "yyyy-MM-dd"),
                end: format(add(new Date(), { days: 8 }), "yyyy-MM-dd"),
            },
            currentValue: this.value,
            masks: {
                input: "DD/MM/YYYY",
                weekdays: "WW",
            },
            attribute: {
                highlight: {
                    start: {
                        style: {
                            backgroundColor: "#662d91",
                        },
                        contentStyle: {
                            color: "#fff",
                        },
                    },
                    base: {
                        style: {
                            backgroundColor: "#e1d5e9",
                        },
                    },
                    end: {
                        style: {
                            backgroundColor: "#662d91",
                        },
                        contentStyle: {
                            color: "#fff",
                        },
                    },
                },
            },
            attributes: [
                {
                    key: "today",
                    highlight: {
                        class: "bg-warning",
                    },
                    dates: new Date(),
                },
            ],
            modelConfig: {
                type: "string",
                mask: "YYYY-MM-DD", // Uses 'iso' if missing
            },
        };
    },
    methods: {
        handlePopoverWillShow() {
            const wrapper = document.getElementsByClassName("vc-popover-content-wrapper")[0];
            const body = document.getElementsByTagName("body")[0];

            wrapper.classList.add("active");
            body.classList.add("mobile-overflow-hidden");
        },

        handlePopoverDidHide() {
            const wrapper = document.getElementsByClassName("vc-popover-content-wrapper")[0];
            const body = document.getElementsByTagName("body")[0];

            wrapper.classList.remove("active");
            body.classList.remove("mobile-overflow-hidden");
        },
    },
    watch: {
        value(val) {
            this.currentValue = val;
        },
        currentValue(val) {
            // allows us to use v-model on our input.
            this.$emit("input", val);
        },
    },
};
</script>
