<template>
    <div class="">
        <DatePicker
            :columns="$screens({ default: 1, md: 2 })"
            :rows="$screens({ default: 2, md: 1 })"
            v-model="range"
            :masks="masks"
            is-range
            :step="1"
            :min-date="new Date()"
            :disabled-dates="disabledDates()"
            :locale="locale"
            :first-day-of-week="2"
            class="custom-calendar"
            :select-attribute="attribute"
            :drag-attribute="attribute"
            :attributes="attributes"
            :popover="{
                visibility: 'click',
                keepVisibleOnInput: true,
                placement: 'bottom',
            }"
            ref="calendar"
            :model-config="modelConfig"
            @popoverWillShow="handlePopoverWillShow()"
            @popoverDidHide="handlePopoverDidHide()"
        >
            <!-- :model-config="modelConfig" -->
            <!-- @input="() => $emit('datepickerChange', range)" -->
            <!-- :popover-keep-visible-on-input="true" -->
            <!-- @input="onDateRangeChange"  -->

            <template v-slot="{ inputValue }">
                <div class="d-flex cursor-pointer" @click="showCalendar">
                    <div
                        class="box-start d-flex align-items-center"
                        :class="{ active: !isApplied }"
                    >
                        <i class="fa fa-calendar p-3 text-primary"></i>
                        <div class="lh-sm">
                            <span class="text-gray-3">{{$trans.check_in}}</span><br />
                            <input readonly :value="inputValue.start" />
                        </div>
                    </div>
                    <div
                        class="
                            box-end
                            d-flex
                            align-items-center
                            justify-content-end
                        "
                        :class="{ active: isApplied }"
                    >
                        <div class="lh-sm ps-3 pe-1">
                            <span class="text-gray-3">{{$trans.check_out}}</span><br />
                            <input readonly :value="inputValue.end" />
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="text-center p-3 w-100">
                        <span
                            class="
                                text-gray-1a
                                fs-7
                                p-1
                            "
                            >{{$trans.dates_are_not_available}}</span
                        >
                </div>
                <div v-if="range.end === null" class="text-center p-3">
                    <span
                        class="
                            btn btn-secondary
                            min-height-55
                            fs-24
                            text-white
                            min-width-170
                            mx-2
                        "
                        @click="cancelDates"
                    >
                        Cancel
                    </span>
                </div>
                <div v-else class="text-center p-3 calendar-buttons-container bg-white w-100">
                    <span
                        class="
                            calendar-button
                            btn btn-secondary
                            min-height-55
                            fs-24
                            text-white
                            min-width-170
                            mx-2
                        "
                        @click="resetDates"
                    >
                        {{$trans.reset}}
                    </span>

                    <span
                        class="
                            calendar-button
                            btn btn-warning
                            min-height-55
                            fs-24
                            hover-bg-primary
                            text-white
                            min-width-170
                            mx-2
                        "
                        @click="applyDates"
                        :style="{order:2}"
                    >
                        {{$trans.apply}}
                    </span>
                    <div class="mt-3" :style="{order:1}">
                        <span
                            class="
                                calendar-button
                                text-primary
                                fs-5
                                bg-opacity-25 bg-warning
                                p-1
                                nights-info
                            "
                            >{{ nights }} {{$trans.nights}}</span
                        >
                    </div>
                </div>
            </template>
        </DatePicker>
    </div>
</template>

<script>
import {getQueryStringParameters} from '@/components/common/helper'

import {add, differenceInDays, format} from "date-fns";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
    name: "BookingDatepicker",
    components: {
        DatePicker,
    },
    props: {
        lang: {
            type: String,
            default: 'en'
        },
        initStart: {
            type: String,
            default: format(add(new Date(), { days: 1}),'yyyy-MM-dd'),
        },
        initEnd: {
            type: String,
            default: format(add(new Date(), { days: 8}),'yyyy-MM-dd'),
        },
        init_data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            locale: this.lang,
            isApplied: false,
            range: {
                start: format(add(new Date(), { days: 1}),'yyyy-MM-dd'),
                end: format(add(new Date(), { days: 8}),'yyyy-MM-dd')
            },
            masks: {
                input: "WWW, D MMM",
                weekdays: "WW",
            },
            attribute: {
                highlight: {
                    start: {
                        style: {
                            backgroundColor: "#662d91",
                        },
                        contentStyle: {
                            color: "#fff",
                        },
                    },
                    base: {
                        style: {
                            backgroundColor: "#e1d5e9",
                        },
                    },
                    end: {
                        style: {
                            backgroundColor: "#662d91",
                        },
                        contentStyle: {
                            color: "#fff",
                        },
                    },
                },
            },
            attributes: [
                {
                    key: "today",
                    highlight: {
                        class: "bg-warning",
                    },
                    dates: new Date(),
                },
            ],
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            }
        };
    },
    created() {
        // Fetch user's locale using custom API (eg. 'en-ZA')
        // this.locale =  'bg'
    },
    methods: {
        resetDates() {
            this.range = {
                start: this.initStart,
                end: this.initEnd
            };
            this.cancelDates() 
            // this.isApplied = false;
        },

        applyDates() {
            this.$refs.calendar.hidePopover();
            this.isApplied = true;
        },

        cancelDates() {
            this.$refs.calendar.hidePopover();
            this.isApplied = false;
        },

        showCalendar() {
            this.$refs.calendar.showPopover();
        },

        fillWithQueryString(){
            let queryString = getQueryStringParameters();

            if (queryString) {
                // console.log(queryString);
                if (queryString.start && queryString.end) {
                    this.range.start = queryString.start
                    this.range.end = queryString.end
                }
            }
        },

        mountActions() {
            let queryString = getQueryStringParameters();

            if(queryString?.start) {
                this.resetDates()
                this.fillWithQueryString()
            } else {
                // this.showCalendar()  this line opens the calendar by default which is not correct for some of the pages
            }
        },

        handlePopoverWillShow(){
            const wrapper = document.getElementsByClassName('vc-popover-content-wrapper')[0]
            const body = document.getElementsByTagName('body')[0]

            wrapper.classList.add('active')
            body.classList.add('mobile-overflow-hidden')
        },

        handlePopoverDidHide(){
            const wrapper = document.getElementsByClassName('vc-popover-content-wrapper')[0]
            const body = document.getElementsByTagName('body')[0]

            wrapper.classList.remove('active')
            body.classList.remove('mobile-overflow-hidden')

        },

        disabledDates() {
          if (this.init_data != undefined) {
            return this.init_data.stop_sale_dates
          } else {
            return []
          }
        }

    },
    computed: {
        nights() {
            return differenceInDays(
                new Date(this.range.end),
                new Date(this.range.start)
            );
        },
    },
    watch:{
        'range': {
            handler: function (val) {
                this.$emit('datepickerChange', this.range)
            },
            deep: true
        },
    },
    mounted() {
           this.mountActions()
    },
};
</script>