<template>
  <ValidationProvider tag="div" :rules="rules" :immediate="immediate" :vid="vid" v-slot="v" ref="observerRef">
    <div class="form-check">
      <input v-model="currentValue"
            type="radio"
            :value="valueRadio"
            :name="name"
            :id="fieldID"
            :class="fieldClass"
            :disabled="disabled"
      >
      <label v-if="label" :class="labelClass" :for="fieldID">
        <span v-html="label"></span>
      </label>
      <div v-if="(v.errors[0] || serverErrors && serverErrors[name]) && !exportErrors" class="text red xl-fs-13">
          {{ v.errors[0] || serverErrors[name][0] }}
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
/** 
 * Example:
    <div class="inline fields xl-pt-15">
      <div class="ui radio checkbox field">
          <RadioButton
              v-model="fields.fitness3" 
              name="fitness3"
              fieldID="fitness3Yes"
              valueRadio="yes"
              rules="required"
              :label="$t('PHP_FORM_YES')"
              :exportErrors="true"                                 // this line emit errors as event and hide the default errors container
              @form-field-error="handleExportedFormFieldError"     // this handle method come by default with formControlledMixin.js. Add this line only on one of the fields of your radio group.
          />
      </div>
      <div class="ui radio checkbox field">
          <RadioButton
              v-model="fields.fitness3" 
              name="fitness3"
              fieldID="fitness3No"
              valueRadio="no"
              rules="required"
              :label="$t('PHP_FORM_NO')"
              :exportErrors="true"
          />
      </div>
  </div>
  <div v-if="errors.fitness3" class="text red xl-fs-13">
      {{errors.fitness3}}
  </div>
*/

import { ValidationProvider } from 'vee-validate';

export default {
  name: 'RadioButtonField',
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    valueRadio: {
      type: [String, Number],
      default: ''
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    vid: {
      type: String,
      default: undefined
    },
    immediate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    serverErrors: {
      type: [String, Object],
      default: undefined 
    },
    exportErrors: {
      type: [Boolean],
      default: false 
    },
    fieldClass: {
      type: String,
      default: 'form-check-input' 
    },
    fieldID: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    labelClass: {
      type: [String, Object],
      default: 'form-check-label' 
    },
  },
  data() {
    return {
      currentValue: this.value,
      valProvider: null
    }
  },

  watch: {
    value (val) {
      this.currentValue = val;
    },
    currentValue (val) {
      // allows us to use v-model on our input.
      if (val === this.valueRadio) {
        this.$emit('input', val);         
      }
    },
    // exportedErrors(val) {
    //     this.$emit('form-field-error', val)          
    // },
  },

  computed: {
    exportedErrors(){
      if(this.valProvider && this.valProvider.errors.length > 0 && this.exportErrors){
        return {
          name: this.name,
          errors: this.valProvider.errors || this.serverErrors
        }
      }

      return {
        name: this.name,
        errors: null
      };
    }
  },

  mounted(){
     this.valProvider = this.$refs.observerRef;
     this.$watch('exportedErrors', (val, oldVal) => {
        if (val.errors !== oldVal.errors) {
          this.$emit('form-field-error', val)            
        }
     });
  }
};
</script>
