<template>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">                                                  
        <form @submit.prevent="handleSubmit(submitForm)" accept-charset="UTF-8" style="max-width: 430px" class="mt-5">
            <div class="p-2 text-center">
                <i class="d-inline-block my-vacantina-logo"></i>
            </div>
            <div class="p-2 text-center">
              <span v-if="type === 'register'">{{trans.register_to_manage_your_customised_content}}</span>
              <span v-if="type === 'sign_in'">{{trans.sign_in}}</span>
              <span v-if="type === 'reset'">{{trans.reset_password_lbl}}</span>
            </div>
            <div v-if="type === 'register'" class="d-flex flex-wrap">
                <div class="flex-50 sm-flex-100 p-2">
                    <Input v-model="fields.first_name"
                        name="first_name"
                        rules="required"
                        type="text"
                        fieldClass="custom-input w-100 border-0 p-2"
                        :placeholder="trans.first_name"
                        :serverErrors="errors ? errors : null"
                    /> 
                </div>
                <div class="flex-50 sm-flex-100 p-2">
                    <Input v-model="fields.last_name"
                        name="surname"
                        rules="required"
                        type="text"
                        fieldClass="custom-input w-100 border-0 p-2"
                        :placeholder="trans.last_name"
                        :serverErrors="errors ? errors : null"
                    /> 
                </div>
            </div>
            <div class="d-flex flex-wrap">
                <div class="flex-50 sm-flex-100 p-2">
                    <Input v-model="fields.email"
                        name="email"
                        rules="required|email"
                        type="email"
                        fieldClass="custom-input w-100 border-0 p-2"
                        :placeholder="trans.email"
                        :serverErrors="errors ? errors : null"
                    /> 
                </div>
            </div>
            <div v-if="type === 'register'" class="d-flex flex-wrap">
                <div class="flex-50 sm-flex-100 p-2">
                    <Input v-model="fields.phone"
                        name="phone"
                        type="text"
                        fieldClass="custom-input w-100 border-0 p-2"
                        :placeholder="trans.phone"
                    />
                </div>
            </div>
            <div v-if="reg_error" class="text-danger fs-13" role="alert">
              {{reg_error}}
            </div>
            <div v-if="type === 'register' || type === 'sign_in'" class="d-flex flex-wrap">
                <div class="w-100 p-2">
                  <div class="field has-addons">
                    <div class="control is-expanded">
                      <div v-if="!passwordHidden" class="password-wrap">
                        <Input type="text"
                            v-model="fields.password1"
                            name="password"
                            :rules="`required|min:6, ${trans.password}`"
                            fieldClass="custom-input w-100 border-0 p-2"
                            :placeholder="trans.password"
                            :serverErrors="errors ? errors : null"
                        />
                        <div class="eyeButton">
                          <span class="display-eye" @click="hidePass">
                              <i class="fa fa-eye" aria-hidden="true"></i>
                          </span>
                        </div>
                      </div>
                      <div v-if="passwordHidden" class="password-wrap">
                        <Input type="password"
                            v-model="fields.password1"
                            name="password"
                            :rules="`required|min:6, ${trans.password}`"
                            fieldClass="custom-input w-100 border-0 p-2"
                            :placeholder="trans.password"
                            :serverErrors="errors ? errors : null"
                        />
                        <div class="eyeButton">
                          <span class="display-eye" @click="showPass">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="d-flex flex-wrap">
                <div v-if="type === 'register'" class="w-100 p-2">
                    <Checkbox 
                        v-model="fields.terms_and_conditions"
                        name="terms_and_conditions"
                        fieldID="terms_and_conditions"
                        labelClass="fs-14"
                        rules="reqTrue"
                        :label="`${trans.accept_the_terms_and_conditions}*`"    
                        :serverErrors="errors ? errors : null"
                    />
                </div>
                <div v-if="type === 'register'" class="w-100 p-2">
                    <Checkbox
                        v-model="fields.subscribed"
                        labelClass="fs-14"
                        name="subscribed"
                        fieldID="subscribed"
                        :label="trans.want_to_receive_current_offers"
                        :serverErrors="errors ? errors : null"
                    />
                </div>
                <div v-if="login_error" class="text-danger fs-13" role="alert">
                  {{login_error}}
                </div>
                <div class="w-100 p-2 text-center">
                    <button
                            class="btn btn-lg text-white fw-500"
                            :class="invalid  ? 'disabled btn-secondary': 'btn-warning hover-bg-primary'"
                            type="submit"
                        >
                            <span v-if="type === 'register'">{{trans.register}}</span>
                            <span v-if="type === 'sign_in'">{{trans.sign_in}}</span>
                            <span v-if="type === 'reset'">{{trans.reset}}</span>
                    </button>
                </div>
            </div>
            <div v-if="type === 'register' || type === 'sign_in'" class="d-flex flex-wrap">
                <div v-if="type === 'sign_in'" class="w-100 p-2 text-center mt-3">
                    <div><a :href="urls.password_reset" class="text-primary">{{trans.forgot_password}}</a> </div>
                </div>
                <div v-if="type === 'register'" class="w-100 p-2 text-center mt-3">
                    <div>{{trans.already_have_an_account}} <a :href="urls.sign_in_url" class="text-primary">{{trans.sign_in}}</a> </div>
                </div>
                <div v-else class="w-100 p-2 text-center mt-3">
                    <div>{{trans.dont_have_an_account}} <a :href="urls.register_url" class="text-primary">{{trans.create_one}}</a> </div>
                </div>
                <div class="w-100 p-2 text-center mt-3">
                    <div>{{trans.or_login_with}}</div>
                    <div class="d-flex align-items-center justify-content-center">
                        <a :href="urls.google_sign_in_url" class="text-primary m-2"><i class="vac-icon box-40 i-google"></i></a>
                        <a :href="urls.facebook_sign_in_url" class="text-primary m-2"><i class="vac-icon box-40 i-facebook"></i></a>
                    </div>
                </div>
            </div>               
        </form>      
	</ValidationObserver>	
</template>

<script>
import Input from '@/components/common/fields/InputField';
import Checkbox from '@/components/common/fields/CheckboxField';

import formControlledMixin from '@/mixins/formMixin';

export default {
    name: "RegisterLogin",
        mixins: [ 
            formControlledMixin 
        ],
    components: {
        Input,
        Checkbox
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        urls: {
            type: Object,
            required: true,
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            login_error: '',
            reg_error: '',
            action: this.urls.request_url,
            formActionType: 'post',
            passwordHidden: {
                    default: false,
                    type: Boolean
            }
        };
    },
    methods: {
        submitForm() {
            // this.action = this.action.replace('None', this.fields.email);
            this.submit();
        },
        hidePass() {
            this.passwordHidden = true;
        },
        showPass() {
            this.passwordHidden = false;
        }
    }
};
</script>
