<template>
<div>
    <div class="bg-white rounded mt-3">
        <div class="wizard d-flex nav nav-tabs"  role="tablist">
            <div class="item flex-1 d-flex align-items-center justify-content-center" 
                :class="init_data.step === 1 ? 'current' : null"
                data-bs-toggle="tab" 
                data-bs-target="#customerData"
                type="button" 
                role="tab" 
                aria-controls="customerData" 
                aria-selected="true"
                id="customer-data"
            >
                <span class="step-num rounded-circle me-1" :class="init_data.step === 1 ? 'text-white bg-black' : 'border border-dark text-dark'">1</span>
                <span class="mobile-hidden">{{trans.customer_data}}</span>
            </div>
            <div class="item flex-1 d-flex align-items-center justify-content-center" 
                :class="init_data.step === 2 ? 'current' : null"
                data-bs-toggle="tab" 
                data-bs-target="#paymentTab"
                type="button" 
                role="tab" 
                aria-controls="paymentTab" 
                aria-selected="false"
                id="payment-tab"
            >
                <span class="step-num rounded-circle me-1" :class="init_data.step === 2 ? 'text-white bg-black' : 'border border-dark text-dark'">2</span>               
                <span class="mobile-hidden">{{trans.payment}}</span>
            </div>
            <div class="item flex-1 d-flex align-items-center justify-content-center" 
                :class="init_data.step === 3 ? 'current' : null"
                data-bs-toggle="tab" 
                data-bs-target="#confirmationTab"
                type="button" 
                role="tab" 
                aria-controls="confirmationTab" 
                aria-selected="false"
                id="confirmation-tab"
            >
                <span class="step-num rounded-circle me-1" :class="init_data.step === 3 ? 'text-white bg-black' : 'border border-dark text-dark'">3</span>
                <span class="mobile-hidden">{{trans.confirmation}}</span>
            </div>
        </div>
    </div>
    <div class="tab-content">
        <div
            v-if="init_data.step === 1" 
            class="tab-pane fade" 
            :class="init_data.step === 1 ? 'show active' : null" 
            id="customerData" 
            role="tabpanel" 
            aria-labelledby="customer-data"
        > 
            <CustomerDataTab
                :init_data="init_data"
                :rooms="init_data.rooms"
                :trans="trans"
                :urls="urls"
                @fieldsUpdate="(val) => $emit('fieldsUpdate', val)" 
            />
        </div>
        <div class="tab-pane fade" 
            v-if="init_data.step === 2"
            :class="init_data.step === 2 ? 'show active' : null" 
            id="paymentTab" 
            role="tabpanel" 
            aria-labelledby="payment-tab"
        >
            <PaymentTab
               :init_data="init_data"
               :final_amounts="final_amounts"
               :trans="trans"
               :urls="urls"
               @fieldsUpdate="(val) => $emit('fieldsUpdate', val)"
            />
        </div>
        <div class="tab-pane fade" 
            v-if="init_data.step === 3"
            :class="init_data.step === 3 ? 'show active' : null" 
            id="confirmationTab" 
            role="tabpanel" 
            aria-labelledby="confirmation-tab"
        >
            <ConfirmationTab
                :trans="trans"
            />
        </div>
    </div>
</div>      
</template>

<script>

import CustomerDataTab from "./CustomerDataTab.vue";
import PaymentTab from "./PaymentTab.vue";
import ConfirmationTab from "./ConfirmationTab.vue";

export default {
    name: "PaymentTabs",
    components: {
       CustomerDataTab,
       PaymentTab,
       ConfirmationTab
    },
    props: {
        init_data: {
            type: Object,
            required: true,
        },
        final_amounts: {
            type: Object,
            default: () => {
                return {}
            }
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        },
        urls: {
            type: Object,
            required: true,
        },
    }
};
</script>