<template>
    <div>
        <div class="bg-white p-3 rounded mt-3 xs-mt-2"> 
            <div class="m-auto max-width-600">
                <div><a :href="urls.contract_url">{{trans.contract_link_lbl}}*</a></div>
                <div>&nbsp;</div>
                <div class="fs-18 fw-500">{{trans.payment_methods}}</div>
                <div class="mt-3">
                    <RadioButton
                        v-model="fields.payment_method" 
                        name="payment_method"
                        fieldID="debit_credit_card"
                        valueRadio="debit_credit_card"
                        rules="required"
                        :label="trans.debit_credit_card"
                    />

                    <RadioButton
                        v-model="fields.payment_method" 
                        name="payment_method"
                        fieldID="bank_transfer"
                        valueRadio="bank_transfer"
                        rules="required"
                        :label="trans.bank_transfer"
                    />                 
                </div>
                <!-- Debit/Credit Card -->  
                <div v-show="fields.payment_method === 'debit_credit_card'" class="mt-3">
                    <div id="embeddedCheckout"></div>
                </div>
                <!-- Bank Transfer -->  
                <div v-if="fields.payment_method === 'bank_transfer'" class="mt-3">
                    <!-- <div class="fs-18 fw-500">1. Please, select the amount of deposit:</div>
                    <div class="mt-3 d-inline-block">
                        <RadioButton
                            v-model="fields.full_deposit" 
                            name="full_deposit"
                            fieldID="full_deposit_no"
                            :valueRadio="0"
                            rules="required"
                            :label="`Pay ${init_data.discout} deposit - ${final_amounts.percent_of_amount}${init_data.currency}`"
                        />
                        <RadioButton
                            v-model="fields.full_deposit" 
                            name="full_deposit"
                            fieldID="full_deposit_yes"
                            :valueRadio="1"
                            rules="required"
                            :label="`Pay 100% deposit - ${init_data.amount}${init_data.currency}`"
                        />                 
                    </div> -->
                    <div>
                        <!-- IBAN -->
                        <div class="bg-gray-2 p-3 rounded mt-3 fw-500">
                            <div>{{init_data.bank_transfer.invoice_data.name}}</div>
                            <div>{{init_data.bank_transfer.invoice_data.bank_name}}</div>
                            <div>
                                <span class="text-secondary">{{trans.bank_account_bgn}} </span>
                                <span class="white-space-nowrap">{{init_data.bank_transfer.invoice_data.bank_account}}</span>
                            </div>
                            <div>
                                <span class="text-secondary white-space-nowrap">SWIFT: </span>
                                <span class="white-space-nowrap">{{init_data.bank_transfer.invoice_data.swift}}</span>
                            </div>
                            <div class="mt-3">
                                <span>{{trans.as_a_subject_for_the_transfer_please_use_code}} </span>
                                <span class="text-primary white-space-nowrap">{{init_data.bank_transfer.invoice_data.conf_code}}</span>
                            </div>
                        </div>

                        <div class="mt-3">
                            <Checkbox 
                                v-model="fields.bank_transfer.payment_confirmation"
                                name="payment_confirmation"
                                fieldID="payment_confirmation"
                                rules="reqTrue"
                                :label="trans.send_copy_of_the_payment_document_note"    
                            />
                        </div>
                        <!-- <div class="mt-3 text-center">
                            <div v-if="invalid" class="text-danger fw-500 text-center">Please fill the form</div>
                            <span :class="invalid  ? 'disabled btn-secondary': 'btn-warning hover-bg-primary'"
                                class="btn btn-lg text-white fw-500 w-100 max-width-250"
                                target="_blank"
                            >
                                OK
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>           
        </div>
    </div>
</template>

<script>
// import Vue from "vue";
import {initMyPos} from './myPos'

import RadioButton from '@/components/common/fields/RadioButtonField';
import Checkbox from '@/components/common/fields/CheckboxField';
import Input from '@/components/common/fields/InputField';

export default {
    name: "PaymentTab",
    components: {
       RadioButton,
       Checkbox,
       Input
    },
    props: {
        init_data: {
            type: Object,
            default: () => {
                return {}
            }
        },
        final_amounts: {
            type: Object,
            default: () => {
                return {}
            }
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        },
        urls: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fields: {
                // full_deposit: this.init_data.full_deposit,
                payment_method: 'debit_credit_card',
                bank_transfer: {
                    payment_confirmation: 0,
                }
            }
        }
    },
    methods: {
       initMyPos
    },
    watch: {
        fields: {
            deep: true,
            handler(){
              this.$emit('fieldsUpdate', this.fields)     
            }
        },
         // TODO: chage the amount on percentage changed
        // 'fields.full_deposit': {
        //         handler(val){
        //             this.initMyPos(
        //                 'embeddedCheckout', 
        //                 this.init_data.amount
        //             )
        //     }
        // }
    },
    mounted() {
        this.initMyPos(
            'embeddedCheckout', 
            this.init_data.amount,
            this.init_data.booking,
            this.init_data.currency_code,
            this.init_data.language,
            this.init_data.mypos_test,
            this.init_data.mypos_sid,
            this.init_data.mypos_wallet_number,
            this.init_data.mypos_url,
            this.init_data.mypos_key_index,
            this.init_data.mypos_url_notify,
            this.init_data.mypos_absolut_uri,
            this.init_data.mypos_is_sandbox
        )
    }
}
</script>