<template>
    <div class="fiters-container sticky-top hotel-page-filters-container m-2" style="top:70px">
        <!--  If room not chosen - Search Component-->
        <div v-show="!is_valid">
            <Search 
                :is_mobile="true"
                :urls="urls"
                :lang="lang"
                :trans="trans"
                :init_data="init_data"
            ></Search>
        </div>

        <!-- If room chosen - Search Component-->
        <div v-show="is_valid">
            <div class="pb-1 d-flex justify-content-end">
                <a class="panel-collapse collapsed btn btn-warning btn-small" data-bs-toggle="collapse" href="#collapseSearch" role="button" aria-expanded="false" aria-controls="collapseSearch">
                    <span><i class="fa fa-calendar ms-1 text-secondary" aria-hidden="true"></i> {{ trans.change_dates ? trans.change_dates : 'Change dates' }}</span>
                </a>
            </div> 
                <div class="collapse" id="collapseSearch">
                        <Search 
                        :is_mobile="true"
                        :urls="urls"
                        :lang="lang"
                        :trans="trans"
                        :init_data="init_data"
                    ></Search>
                </div>
           
        </div>
      

        <div v-if="rooms" id="roomsOrder" class="mt-3 xs-mt-0 bg-white p-3 xs-p-2 rounded">
            <div v-for="(room, key) in rooms" :key="key" class="mt-2">
                <strong>{{key.replace('room_', '')}}. {{room.name}}</strong>
                <div class="ps-3 xs-fs-14">
                    <div>{{room.adults}} {{trans.adults}}<span v-if="room.children" class="ms-2">, {{room.children}} {{trans.children}}</span></div>
                    <div :class="room.status[0].value === 1 ? 'text-success-2' : 'text-warning'">{{room.status[0].label}}</div>
                    <div class="d-flex justify-content-end">
                        <span v-if="room.reg_price !== room.spo_price" class="text-decoration-line-through mx-1">{{room.reg_price}}{{room.currency}}</span>
                        <strong class="mx-1">{{room.spo_price}}{{room.currency}}</strong>
                    </div> 
                </div>                                     
            </div>
            <div class="mt-3 pt-2 ps-3 d-flex align-items-end justify-content-between border-top">
                <strong>{{trans.total}}:</strong>
                <div class="d-flex align-items-end justify-content-end  lh-1 ">
                    <span v-if="final_amounts.reg_price !== final_amounts.spo_price" class="text-decoration-line-through mx-1">{{final_amounts.reg_price}}{{details.currency}}</span>
                    <strong class="fs-3 mx-1">{{final_amounts.spo_price}}{{details.currency}}</strong>
                </div>         
            </div>
            <div class="mt-3">
                <div v-if="!is_valid" class="text-danger fw-500 text-center">{{trans.select_accommodation_type}}</div>
                <a :href="urls.hotel_payment_url + order_query_string" :class="!is_valid ? 'disabled btn-secondary': 'btn-warning hover-bg-primary'"
                    class="btn btn-lg text-white fw-500 w-100"
                >
                    {{trans.book}}
                </a>
            </div>
        </div>
        <!-- Free Cancellation before -->
        <div class="mt-3 xs-mt-0 bg-white p-3 xs-p-0 rounded xs-fs-14">
            <!-- <div class="p-2">
                <strong class="text-success-2">{{trans.pay_only_10_deposit}}</strong>
            </div> -->
            <div class="p-2 text-center">
                <strong class="text-success-2 mx-auto"><img src="@/assets/images/hotel-checkout.png" alt="" srcset=""></strong>
            </div>
            <!-- <div class="p-2 text-center">
                <div v-if="init_data.cancel_date" >
                    <strong class="">{{trans.free_cancellation_before}}</strong>
                </div>
                <span>{{init_data.cancel_date}}</span>
            </div> -->
        </div>
        <div class="mt-3 xs-mt-0 bg-white p-3 xs-p-0 rounded xs-fs-14">
            <div class="text-decoration-underline text-center">
                <strong class="text-danger">{{trans.hotel_offer}}!</strong> {{trans.book_early_pay_less}}
            </div>
        </div>
    </div>
</template>

<script>
import Search from "@/components/search/Index.vue";

export default {
    name: 'Order',
    components: {
        Search
    },
    props: {
        init_data: {
            type: Object,
            required: true,
        },
        rooms: {
            type: Object
        },
        urls: {
            type: Object,
            required: true
        },
        lang: {
            type: String,
            default: 'en'
        },
        details: {
            type: Object,
            required: true
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        },
        final_amounts: {
            type: Object,
            default: () => {
                return {}
            }
        },
        order_query_string: {
            type: String,
            default: '',
        },
        is_valid: {
            type: Boolean,
            // default: true,
        }
    }
}
</script>