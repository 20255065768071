<template>
  <div>
        <!-- Rooms -->
        <div v-for="(room, key) in rooms" :key="key" class="bg-white p-3 xs-p-2 rounded mt-3 xs-mt-2">
            <div class="m-auto max-width-600">
                <div class="fw-500 fs-5 xs-fs-18 m-2">{{key.replace('room_', '')}}. {{room.name}}</div>
                <!-- Adults -->
                <div v-for="(adult) in room.adults" :key="adult" class="">
                    <div class="text-primary mx-2 mt-3 xs-mt-2 xs-fs-14">{{trans.guest}} {{adult}} - {{trans.room_registrant}} - {{trans.details}}*</div>
                    <div class="d-flex flex-wrap">
                        <div class="flex-50 sm-flex-100 p-2 xs-p-1">
                            <Input v-model="fields[key][`adult_${adult}`]['first_name']"
                                name="first_name"
                                rules="required"
                                type="text"
                                fieldClass="custom-input w-100 bordered p-2 xs-min-h-auto"
                                :placeholder="trans.first_name"
                                :serverErrors="errors ? errors : null"
                            /> 
                        </div>
                        <div class="flex-50 sm-flex-100 p-2 xs-p-1">
                            <Input v-model="fields[key][`adult_${adult}`]['surname']"
                                name="surname"
                                rules="required"
                                type="text"
                                fieldClass="custom-input w-100 bordered p-2 xs-min-h-auto"
                                :placeholder="trans.surname"
                                :serverErrors="errors ? errors : null"
                            /> 
                        </div>
                        <div v-if="adult === 1 && key=== 'room_1'" class="flex-50 sm-flex-100 p-2 xs-p-1">
                            <Input v-model="fields[key][`adult_${adult}`]['email']"
                                name="email"
                                rules="required|email"
                                type="email"
                                fieldClass="custom-input w-100 bordered p-2 xs-min-h-auto"
                                :placeholder="trans.email"
                                :serverErrors="errors ? errors : null"
                            /> 
                        </div>
                        <div v-if="adult === 1 && key=== 'room_1'" class="flex-50 sm-flex-100 p-2 xs-p-1">
                            <Input v-model="fields[key][`adult_${adult}`]['phone']"
                                name="phone"
                                :rules="{required: true,  regex: /^([0-9\s\-\+\(\)]*)$/ }"
                                type="phone"
                                fieldClass="custom-input w-100 bordered p-2 xs-min-h-auto"
                                :placeholder="trans.phone"
                                :serverErrors="errors ? errors : null"
                            /> 
                        </div>
                    </div>                       
                </div>
                <!-- Children -->
                <div v-if="room.children_ages.length > 0">
                    <div class="text-primary mx-2 mt-3 xs-mt-2">{{trans.date_of_birth_of_children}}**</div>
                    <div class="d-flex flex-wrap">
                        <div v-for="(age, key_c) in room.children_ages" :key="key_c + '_c'" class="w-50 sm-flex-100 p-2 xs-p-1">
                            <!-- <Input v-model="fields[key][`child_${key_c + 1}_date_of_birth`]"
                                :name="`child_${key_c + 1}_date_of_birth`"
                                rules="required"
                                type="date"
                                fieldClass="custom-input w-100 bordered p-2"
                                :placeholder="`${trans.date_of_birth_child} ${key_c + 1} *`"
                                :serverErrors="errors ? errors : null"
                            /> -->
                            <Datepicker 
                                v-model="fields[key][`child_${key_c + 1}_date_of_birth`]"
                                :name="`child_${key_c + 1}_date_of_birth`"
                                rules="required"
                                type="date"
                                fieldClass="custom-input w-100 bordered p-2 xs-min-h-auto"
                                :placeholder="`${trans.date_of_birth_child} ${key_c + 1}*`"
                                :serverErrors="errors ? errors : null"
                            />
                        </div>
                    </div> 
                </div>
                <div class="fs-12 text-secondary">* {{trans.required}}</div>
                <div v-if="room.children_ages.length > 0" class="fs-12 text-primary">** {{trans.children_calculation_note}}</div>
            </div>
        </div>
        <!-- Additional requirements -->
        <div class="bg-white p-3 xs-p-2 rounded mt-3 xs-mt-2"> 
            <div class="m-auto max-width-600">
                <div class="fs-5 xs-fs-18">{{trans.additional_requirements}}</div>
                <textarea v-model="fields.additional_requirements" name="additional_requirements" class="w-100 p-2" rows="4" :placeholder="trans.your_message_here"></textarea>
            </div>           
        </div>
        <!-- Invoice -->
        <div class="bg-white p-3 xs-p-2 rounded mt-3 xs-mt-2">
            <div class="m-auto max-width-600">
                <Checkbox 
                    v-model="fields.invoice_confirmation"
                    name="invoice_confirmation"
                    fieldID="invoice_confirmation"
                    :label="trans.check_if_you_wish_invoice"    
                />

                <div v-if="fields.invoice_confirmation === 1" class="d-flex flex-wrap">
                    <div class="flex-50 sm-flex-100 p-2 fs-14">
                        <Checkbox
                            v-model="fields.invoice_fields.fl"
                            name="invoice_confirmation"
                            fieldID="invoice_confirmation"
                            :label="trans.fl"
                        />
                    </div>
                    <div class="flex-50 sm-flex-100 p-2 xs-p-1">
                        <Input v-model="fields.invoice_fields.company"
                            name="company"
                            rules="required"
                            type="text"
                            fieldClass="custom-input w-100 bordered p-2 xs-min-h-auto"
                            :placeholder="`${trans.company}*`"
                        /> 
                    </div>
                    <div class="flex-50 sm-flex-100 p-2 xs-p-1">
                        <Input v-model="fields.invoice_fields.vat_id"
                            name="vat_id"
                            rules="required"
                            type="text"
                            fieldClass="custom-input w-100 bordered p-2 xs-min-h-auto"
                            :placeholder="`${trans.vat_number_personal_id}*`"
                        /> 
                    </div>
                    <div class="flex-50 sm-flex-100 p-2 xs-p-1">
                        <Input v-model="fields.invoice_fields.country"
                            name="country"
                            rules="required"
                            type="text"
                            fieldClass="custom-input w-100 bordered p-2 xs-min-h-auto"
                            :placeholder="`${trans.country}*`"
                        /> 
                    </div>
                    <div class="flex-50 sm-flex-100 p-2 xs-p-1">
                        <Input v-model="fields.invoice_fields.city"
                            name="city"
                            rules="required"
                            type="text"
                            fieldClass="custom-input w-100 bordered p-2 xs-min-h-auto"
                            :placeholder="`${trans.city}*`"
                        /> 
                    </div>
                    <div class="flex-50 sm-flex-100 p-2 xs-p-1">
                        <Input v-model="fields.invoice_fields.address_by_registration"
                            name="address_by_registration"
                            rules="required"
                            type="text"
                            fieldClass="custom-input w-100 bordered p-2 xs-min-h-auto"
                            :placeholder="`${trans.address_by_registration}*`"
                        /> 
                    </div>
                    <div v-if="fields.invoice_fields.fl !== 1" class="flex-50 sm-flex-100 p-2 xs-p-1">
                        <Input v-model="fields.invoice_fields.mol"
                            name="address_by_registration"
                            type="text"
                            fieldClass="custom-input w-100 bordered p-2 xs-min-h-auto"
                            :placeholder="`${trans.mol}`"
                        />
                    </div>
                    <div class="flex-100 sm-flex-100 p-2 fs-14">
                        {{trans.invoice_note}}
                    </div>
                </div> 
            </div>                   
        </div>
        <!-- Cancellation rules -->
        <div class="text-white bg-secondary p-3 xs-p-2 rounded mt-3 xs-mt-2"> 
            <div class="m-auto max-width-600">
                <div class="fs-5 xs-fs-18 text-uppercase">{{trans.cancellation_rules}}</div>
                <div class="text-gray-1">
                    <ul v-html="init_data.cancellation_text_free">
<!--                      <li>{{ init_data.cancellation_text_free }}</li>-->
<!--                      <li>{{ init_data.cancellation_text_pay }}</li>-->
<!--                      <li>{{ init_data.cancellation_text_no_show }}</li>-->
                    </ul>
                </div>
            </div>           
        </div>
        <!-- Legal -->
        <div class="bg-white p-3 xs-p-2 rounded mt-3 xs-mt-2 xs-fs-14"> 
            <div class="m-auto max-width-600">
                <div class="">
                    <Checkbox 
                        v-model="fields.conditions_for_cancellation"
                        name="conditions_for_cancellation"
                        fieldID="conditions_for_cancellation"
                        rules="reqTrue"
                        :label="`${trans.i_agree_to_the_conditions_for_cancellation}*`"    
                        :serverErrors="errors ? errors : null"
                    />
                </div>
                <div class="mt-3 xs-mt-2">
                    <Checkbox 
                        v-model="fields.terms_and_conditions"
                        name="terms_and_conditions"
                        fieldID="terms_and_conditions"
                        rules="reqTrue"
                        :label="`${trans.i_have_read_and_agree_to_the} <b><a style='color: blue;' target='_blank' href='${urls.terms_url}'>${trans.terms_and_conditions}*</a></b> ${trans.and_the} <b><a style='color: blue;' href='${urls.contract_blank_url}'>${trans.and_contract}*</a></b>`"
                        :serverErrors="errors ? errors : null"
                    />
                </div>
                <div class="mt-3 xs-mt-2">
                    <Checkbox 
                        v-model="fields.offers_subscription"
                        name="offers_subscription"
                        fieldID="offers_subscription"
                        :label="trans.want_to_receive_current_offers"    
                        :serverErrors="errors ? errors : null"
                    />
                </div>
            </div>           
        </div>
  </div>
</template>

<script>
import Input from '@/components/common/fields/InputField';
import Checkbox from '@/components/common/fields/CheckboxField';
import Datepicker from '@/components/common/fields/Datepicker';

export default {
    name: "CustomerDataTab",
    components: {
       Input,
       Checkbox,
       Datepicker
    },
    props: {
        rooms: {
            type: Object,
            default: () => {
                return {}
            }
        },
        init_data: {
            type: Object,
            default: () => {
                return {}
            }
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        },
        urls: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fields: this.addRooms(),
            errors: {}
        }
    },
    methods: {
        addRooms(){
            let res = {}
            Object.keys(this.rooms).map(r => {
                res[r] = {}
                // debugger
                for (let i = 1; i <= this.rooms[r].adults; i++) {
                    res[r][`adult_${i}`] = {}  
                }

                for (let i = 1; i < this.rooms[r].children_ages.length + 1; i++) {
                    res[r][`child_${i}_date_of_birth`] = null  
                }
            })

            res['invoice_fields'] = {}
            res['invoice_confirmation'] = 0

            return res
        }
    },
    watch: {
        fields: {
            deep: true,
            handler(){
              this.$emit('fieldsUpdate', this.fields)     
            }
        }
    }
}
</script>