<template>
    <div class="bg-warning text-center">
        <div class="container py-4 xs-py-2">
            <h2 class="text-primary fw-500">{{trans.save_your_time}}</h2>
            <p class="fs-14 text-gray-3">{{trans.sign_up_email}}</p>
            <!-- {{-- Form --}} -->
            <div v-show="!success && loaded">
                <Form
                    :urls="urls"
                    :trans="trans"
                    @loaded="handleLoaded"
                    @success="handleSuccess"
                    @error="handleError"
                />
            </div>

            <!-- {{-- Loading --}} -->
            <Loader v-if="!loaded"/>
            
            <div v-if="success" class="text-success text-center">
                <div>{{trans.thank_you_for_your_subscription}}</div>
            </div>
            
            <!-- {{-- Request Server Error --}} -->
            <div v-else-if="errors && errors.request && activeForm !== ''" class="text-danger mt-1 fs-14">
                {{ errors.request }}
            </div>
        </div>
    </div>                   
</template>


<script>
import indexMixin from "@/mixins/indexMixin";
import Form from "./Form.vue";

export default {
    name: "FooterSubscription",
    mixins: [indexMixin],
    components: {
        Form,
    },
    props: {
        urls: {
            type: Object,
            required: true
        },
        trans: {
            type: Object,
            required: true
        }
    }
};
</script>