<template>
    <input
        type="text"
        id="autoComplete"
        class="custom-input w-100 min-width-150"
        spellcheck=false 
        autocorrect="off" 
        autocomplete="off" 
        autocapitalize="off"
    />
    <!-- @change="handleChange($event)" -->
</template>

<script>
import {getQueryStringParameters} from '@/components/common/helper'
import autoComplete from "@tarekraafat/autocomplete.js";

export default {
    data() {
        return {};
    },
    props: {
       endpoint: {
           type: String,
           required: true
       }
    },
    methods: {
        init() {
            const autoCompleteJS = new autoComplete({
                placeHolder: this.$trans.search_text,
                data: {
                    src: async (query) => {
                        try {
                            // Fetch Data from external Source
                            const source = await fetch(`${this.endpoint}?searchText=${query}`);
                            // Data is array of `Objects` | `Strings`
                            const data = await source.json();
                            // Trim white spaces
                            data.map(item => {
                                item.label = item.label.trim()
                            })

                            return data;
                        } catch (error) {
                            return error;
                        }
                    },
                    // Data 'Object' key to be searched
                    keys: ["label"],
                    // filter: (list) => {
                    //     const results = list.filter((item) => {
                    //         const inputValue =
                    //             autoCompleteJS.input.value.toLowerCase();
                    //         const itemValue = item.value.toLowerCase();

                    //         if (itemValue.startsWith(inputValue)) {
                    //             return item.value;
                    //         }
                    //     });

                    //     return results;
                    // },
                },
                resultItem: {
                    highlight: true,
                    element: (item, data) => {
                        // Adding an icon in front of the result
                        const icon = document.createElement("i");
                        let iconClass = data.value.type === 'location' ? 'fa-map-marker-alt' : 'fa-hotel'
                        icon.classList.add('fas', iconClass , 'pe-2', 'text-primary');
                        item.prepend(icon);
                    },
                },
                // resultsList: {
                //    maxResults: 10
                // },
                events: {
                    input: {
                        selection: (event) => {
                            const selection = event.detail.selection.value.label;
                            const val = event.detail.selection.value.value;
                            const type = event.detail.selection.value.type;
                            const label = event.detail.selection.value.label;
                            autoCompleteJS.input.value = selection;
                            autoCompleteJS.input.setAttribute("data-value", val)
                            autoCompleteJS.input.setAttribute("data-type", type)
                            autoCompleteJS.input.setAttribute("data-label", label)
                        }
                    }
                }
            });

            autoCompleteJS.init();
        },

        handleChange(event) {
            this.$emit('destinationChange', {
                value: event.target.getAttribute("data-value"),
                type: event.target.getAttribute("data-type"),
                label: event.target.getAttribute("data-label")
            })
        },


        fillWithQueryString(){
            let queryString = getQueryStringParameters();

            if (queryString) {
                if (queryString.destination && queryString.type && queryString.label) {
                   let input = document.getElementById('autoComplete');
                   input.value = queryString.label

                    this.$emit('destinationChange', {
                        value: queryString.destination,
                        type: queryString.type,
                        label: queryString.label
                    })
                }
            }
        }
    },
    mounted() {
        this.init();
        this.fillWithQueryString();

        // macOS Safari ochange bug fix
        let input = document.getElementById('autoComplete')
        input.addEventListener('blur', (event) => {
            this.handleChange(event) 
        })
    },
};
</script>

<style>
.autoComplete_wrapper {
    position: relative;
}

.autoComplete_wrapper > input {
    margin: 0;
    padding: 0 2rem 0 3.2rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: 1rem;
    text-overflow: ellipsis;
    outline: none;
    background-image: url('../../assets/images/purple_pin.png');
    background-size: 1rem;
    background-position: left 1.05rem top 0.8rem;
    background-repeat: no-repeat;
    background-origin: border-box;
    background-color: #fff;
    transition: all 0.4s ease;
    -webkit-transition: all -webkit-transform 0.4s ease;
}

.autoComplete_wrapper > input::placeholder {
    opacity: .7;
    transition: all 0.3s ease;
    -webkit-transition: all -webkit-transform 0.3s ease;
}

.autoComplete_wrapper > input:hover::placeholder {
    opacity: 1;
    transition: all 0.3s ease;
    -webkit-transition: all -webkit-transform 0.3s ease;
}

.autoComplete_wrapper > input:focus::placeholder {
    padding: 0.1rem 0.6rem;
    font-size: 0.95rem;
    opacity: 1;
}

/* .autoComplete_wrapper > input:focus::selection {
    background-color: rgba(255, 122, 122, 0.15);
} */

/* .autoComplete_wrapper > input::selection {
    background-color: rgba(255, 122, 122, 0.15);
} */

.autoComplete_wrapper > input:hover {
    /* color: rgba(255, 122, 122, 0.8); */
    transition: all 0.3s ease;
    -webkit-transition: all -webkit-transform 0.3s ease;
}

/* .autoComplete_wrapper > input:focus {
    color: rgba(255, 122, 122, 1);
    border: 0.06rem solid rgba(255, 122, 122, 0.8);
} */

.autoComplete_wrapper > ul {
    position: absolute;
    max-height: 226px;
    overflow-y: auto;
    box-sizing: border-box;
    left: 0;
    right: 0;
    margin: 0.5rem 0 0 0;
    padding: 0;
    z-index: 2;
    list-style: none;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #6f859a;
    outline: none;
    transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -webkit-transition: opacity 0.15s ease-in-out;
}

.autoComplete_wrapper > ul[hidden],
.autoComplete_wrapper > ul:empty {
    display: block;
    opacity: 0;
    transform: scale(0);
}

.autoComplete_wrapper > ul > li {
    margin: 0.3rem;
    padding: 0.3rem 0.5rem;
    text-align: left;
    font-size: 1rem;
    color: #212121;
    border-radius: 0.35rem;
    background-color: rgba(255, 255, 255, 1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s ease;
}

.autoComplete_wrapper > ul > li mark {
    background-color: transparent;
    color: #662d91;
    font-weight: bold;
}

.autoComplete_wrapper > ul > li:hover {
    cursor: pointer;
    background-color: rgba(102, 45, 145, 0.15);
}

.autoComplete_wrapper > ul > li[aria-selected="true"] {
    background-color: rgba(102, 45, 145, 0.15);
}

/* @media only screen and (max-width: 600px) {
    .autoComplete_wrapper > input {
        width: 18rem;
    }
} */
</style>