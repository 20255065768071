<template>
    <ValidationObserver v-slot="{ handleSubmit }">                                                  
        <form @submit.prevent="handleSubmit(submitForm)"
            accept-charset="UTF-8" 
            class="d-sm-flex d-block justify-content-center max-width-500 w-100 mx-auto">

            <div class="p-2 xs-pt-0 flex-1">
                <Input v-model="fields.email"
                        name="email"
                        rules="required"
                        type="email"
                        fieldClass="custom-input w-100 border-0 p-2 xs-pt-0"
                        :placeholder="trans.your_email_placeholder"
                        :serverErrors="errors ? errors : null"
                />                                                             
            </div>
            
            <!-- {{-- Submit Button --}} -->
            <div class="p-2">
                <button
                        class="btn btn-primary min-height-55 fs-24 xs-fs-18 hover-text-white min-width-170"
                        type="submit"
                    >
                        {{trans.subscribe}}
                </button>
            </div>
                                                                                    
        </form>      
	</ValidationObserver>	
</template>

<script>

import formControlledMixin from '@/mixins/formMixin';
import Input from '@/components/common/fields/InputField';

export default {
    name: 'SubscriptionForm',
    mixins: [ 
        formControlledMixin 
    ],
    components: {
        Input
    },
    props:{
        urls: {
            type: Object,
            required: true
        },
        trans: {
            type: Object,
            required: true
        }
    },
    data(){
        return{
            action: this.urls.reg_request_url,
            fields: {
                email: ''
            }
        }
    },
     methods:{
        submitForm() {
            this.action = this.action.replace('None', this.fields.email);
            this.submit()
        },
    }
}

</script>