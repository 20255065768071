<template>
    <div class="fiters-container hotel-page-filters-container m-2">
        <div class="sticky-top" style="top:70px">
            <div class="mt-3 xs-mt-0 bg-white p-3 xs-p-2 rounded">
                <div class="lh-1"> 
                    <div class="text-primary fs-4 xs-fs-18 fw-500">{{init_data.hotel.name}}</div>
                    <div class="fs-14">{{init_data.hotel.location}}</div>
                </div>
                <div class="d-flex align-items-center justify-content-between fs-14 fw-bold mt-1">
                <div>{{dateToLocalString(init_data.from_, 'bg-BG')}}</div>
                <i class="fas fa-long-arrow-alt-right fs-4"></i>
                <div>{{dateToLocalString(init_data.to, 'bg-BG')}}</div>
                </div>
                <div class="text-primary fs-14 fw-bold">{{init_data.nights}} {{trans.nights}}</div>
            
                <div v-if="rooms" id="roomsOrder" class="mt-3 xs-mt-1">
                    <div v-for="(room, key) in rooms" :key="key" class="mt-2 xs-fs-14">
                        <strong>{{key.replace('room_', '')}}. {{room.name}}</strong>
                        <div class="ps-3">
                            <div class="d-flex justify-content-end">
                                <strong class="mx-1">{{room.spo_price}}{{init_data.currency}}</strong>
                            </div> 
                        </div>                                     
                    </div>
                    <div class="mt-3 pt-2 ps-3 d-flex align-items-end justify-content-between border-top">
                        <strong>{{trans.total}}:</strong>
                        <div class="d-flex align-items-end justify-content-end  lh-1 ">
                            <strong class="fs-3 mx-1">{{final_amounts.amount}}{{init_data.currency}}</strong>
                        </div>         
                    </div>
                    <div v-if="init_data.step === 1 && final_amounts.percent_of_amount > 0" class="mt-3 pt-2 ps-3 xs-fs-14">
                        <RadioButton
                            v-model="fields.full_deposit" 
                            name="full_deposit_order"
                            fieldID="full_deposit_no"
                            :valueRadio="0"
                            rules="required"
                            :label="`${trans.pay} ${init_data.discout}% ${trans.deposit} - ${final_amounts.percent_of_amount}${init_data.currency}`"
                        />

                        <RadioButton
                            v-model="fields.full_deposit" 
                            name="full_deposit_order"
                            fieldID="full_deposit_yes"
                            :valueRadio="1"
                            rules="required"
                            :label="`${trans.pay} 100% ${trans.deposit} - ${init_data.amount}${init_data.currency}`"
                        />                 
                    </div>
                    <div v-if="!hideButton" class="mt-3 xs-mt-1">
                        <div v-if="invalid" class="text-danger fw-500 text-center">{{trans.please_fill_the_form}}</div>
                        <a :href="urls.hotel_payment_url + order_query_string" :class="invalid  ? 'disabled btn-secondary': 'btn-warning hover-bg-primary'"
                            class="btn btn-lg text-white fw-500 w-100"
                        >
                            {{trans.pay_now}}
                        </a>
                    </div>
                </div>

                <div class="mt-3 xs-mt-1 xs-fs-14 text-center">
                    <div v-if="init_data.step === 1 && init_data.discout > 0" class="p-2 xs-p-0">
                        <strong class="text-success-2">{{trans.pay_only}} {{init_data.discout}}% {{trans.deposit}}!</strong>
                    </div>
                    <div class="p-2 xs-p-0">
                        <strong class="text-success-2"><img src="../../assets/images/hotel-checkout.png" alt="" srcset=""></strong>
                    </div>
                    <div class="p-2 text-center xs-p-0">
                        <div v-if="init_data.free_cancelation_date" >
                            <strong class="">{{trans.free_cancellation_before}}</strong>
                        </div>
                        <span>{{init_data.free_cancelation_date}}</span>
                    </div>
                </div>
            </div>
            <div class="mt-3 xs-mt-1 bg-white p-3 xs-p-2 rounded xs-fs-14">
                <div class="text-decoration-underline text-center">
                    <strong class="text-danger">{{trans.hotel_offer}}!</strong> {{trans.book_early_pay_less}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RadioButton from '@/components/common/fields/RadioButtonField';

export default {
    name: 'Order',
    components: {
        RadioButton
    },
    props: {
        init_data: {
            type: Object,
            // required: true,
            default: () => {
                return {}
            }   
        },
        final_amounts: {
            type: Object,
            default: () => {
                return {}
            }
        },
        urls: {
            type: Object,
            required: true
        },
        invalid: {
            type: Boolean,
            // default: true,
        },
        hideButton: {
            type: Boolean,
            default: false,
        },
        order_query_string: {
            type: String,
            default: '',
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data(){
        return {
            rooms: this.init_data.rooms,
            fields: {
                full_deposit: 1
            }
        }
    },
    methods: {
        dateToLocalString(date, local){
            const event = new Date(date);
            const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

            return event.toLocaleDateString(local , options)

        }
    },
    watch: {
        fields: {
            deep: true,
            handler(){
              this.$emit('fieldsUpdate', this.fields)     
            }
        },
    }

}
</script>

<style>

</style>