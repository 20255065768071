<template>
    <div class="d-flex flex-wrap">
        <ValidationObserver v-slot="{ handleSubmit, invalid }">                                                  
            <form @submit.prevent="handleSubmit(submitForm)" accept-charset="UTF-8" style="max-width: 430px" class="mt-5">
                <div class="p-2 text-center">{{trans.contact_with_us}}</div>
                <div class="d-flex flex-wrap">
                    <div class="flex-50 sm-flex-100 p-2">
                        <Input v-model="fields.first_name"
                            name="first_name"
                            rules="required"
                            type="text"
                            fieldClass="custom-input w-100 border-0 p-2"
                            :placeholder="trans.first_name"
                            :serverErrors="errors ? errors : null"
                        /> 
                    </div>
                    <div class="flex-50 sm-flex-100 p-2">
                        <Input v-model="fields.last_name"
                            name="surname"
                            rules="required"
                            type="text"
                            fieldClass="custom-input w-100 border-0 p-2"
                            :placeholder="trans.last_name"
                            :serverErrors="errors ? errors : null"
                        /> 
                    </div>
                </div>
                <div class="d-flex flex-wrap">
                    <div class="flex-50 sm-flex-100 p-2">
                        <Input v-model="fields.email"
                            name="email"
                            rules="required|email"
                            type="email"
                            fieldClass="custom-input w-100 border-0 p-2"
                            :placeholder="trans.email"
                            :serverErrors="errors ? errors : null"
                        /> 
                    </div>
                    <div class="flex-50 sm-flex-100 p-2">
                        <Input v-model="fields.phone"
                            name="phone"
                            rules="required"
                            type="phone"
                            fieldClass="custom-input w-100 border-0 p-2"
                            :placeholder="trans.phone"
                            :serverErrors="errors ? errors : null"
                        /> 
                    </div>
                    <div class="w-100 p-2">
                        <textarea v-model="fields.message" name="message" class="w-100 p-2 border-0"  rows="5" :placeholder="trans.your_message_here"></textarea>
                        <span class="fs-12">* {{trans.required}}</span>
                    </div>
                    
                    <div class="w-100 p-2 text-end">
                        <button
                                class="btn btn-lg text-white fw-500"
                                :class="invalid  ? 'disabled btn-secondary': 'btn-warning hover-bg-primary'"
                                type="submit"
                            >
                                {{trans.send}}
                        </button>
                    </div>
                </div>               
            </form>      
        </ValidationObserver>
        <div style="max-width: 430px" class="mt-5">
            <div class="p-2 text-center">
                <i class="d-inline-block my-vacantina-logo"></i>
                <div>
                    
                </div>
            </div>
        </div>
    </div>
	
</template>

<script>
import Vue from "vue";
import Input from '@/components/common/fields/InputField';
import Checkbox from '@/components/common/fields/CheckboxField';

import formControlledMixin from '@/mixins/formMixin';

export default {
    name: "ContactUs",
        mixins: [ 
            formControlledMixin 
        ],
    components: {
        Input,
        Checkbox
    },
    props: {
        urls: {
            type: Object,
            required: true,
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            action: this.urls.request_url,
            // formActionType: 'post',
        };
    },
    methods: {
        submitForm() {
            // this.action = this.action.replace('None', this.fields.email);
            this.submit()
        },
    }
};
</script>
