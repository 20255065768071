   
/** 
 * Example
 <template>
    <div>
        <div v-show="!success && loaded">
            <!-- {{-- Form --}} -->
            <SomeFormComponent
                @loaded="handleLoaded"
                @success="handleSuccess"
                @error="handleError"
                @formResponse="handleResponse"
            />
        </div>

        <!-- {{-- Loading --}} -->
        <Loader v-if="!loaded"/>

        <div v-if="success && Object.keys(formResponse).length > 0" class="row">
            <!-- {{-- Success message--}} -->  
        </div>

        <!-- {{-- Request Server Error --}} -->
        <div v-else-if="errors && errors.request" class="red text xl-pt-20 xl-ta-center">
            {{ errors.request }}
        </div>
    </div>
</template>
 
*/

import { parseError, isObject } from "@/components/common/helper.js";
import Loader from "@/components/common/Loader.vue";
export default {
    components:{
       Loader
    },
    data() {
        return {
            loaded: true,
            success: false,
            errors: {},
            // formResponse: {} //optional
        };
    },
    methods: {
        handleLoaded(val) {
            this.loaded = val;
        },
        handleSuccess(val) {
            this.success = val;
        },
        handleError(val) {
            this.errors = this.parseError(val);
        },
        // optional
        // handleResponse(val) {
        //     if (this.isObject(val) && val.hasOwnProperty('someProp')) {
        //         // Do Something with 'val'
        // this.formResponse = val;
        //     }
        // },
        parseError,
        isObject
    },
}