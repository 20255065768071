<template>
    <div class="fiters-container mb-3">
        <div class="mt-3 d-flex align-items-center justify-content-between">
            <h3 class="fw-bold px-3 text-gray-3">{{$trans.filters}}</h3>
            <!-- <span @click="clear()" class="cursor-pointer text-lowercase">{{$trans.clear}} <i class="fas fa-times text-danger"></i></span> -->
        </div>
        <!-- <div class="mt-3 rating-filter">
            <div class="rounded bg-light px-2 py-3 text-gray-3">
                <span class="fw-bold px-2 fs-5">Rating</span>
                <div class="d-flex text-center mt-2">
                    <div class="flex-1 mx-2 rounded-pill border">1</div>
                    <div class="flex-1 mx-2 rounded-pill border">2</div>
                    <div class="flex-1 mx-2 rounded-pill border">3</div>
                    <div class="flex-1 mx-2 rounded-pill border">4</div>
                    <div class="flex-1 mx-2 rounded-pill border">5</div>
                </div>
            </div>
        </div> -->
        <!-- <CheckboxFilter
            :fieldsData="popularFilters"
            title="Popular Filters"
            prefix="pf_"
            @filterUpdate="handleFilterUpdate($event)"
        />
        <CheckboxFilter
            :fieldsData="amenitiesFilters"
            title="Amenities"
            prefix="af_"
            @filterUpdate="handleFilterUpdate($event)"
        /> -->
        <StarsFilter
            :fieldsData="starsFilters"
            :title="$trans.stars"
            prefix="stars_"
            category="stars"
            @filterUpdate="handleFilterUpdate($event)"
        />
        <div v-show="start_date">
          <CheckboxFilter
              v-if="mealsFilters.length > 0"
              :fieldsData="mealsFilters"
              :title="$trans.meal"
              prefix="ml_"
              category="board"
              @filterUpdate="handleFilterUpdate($event)"
          />
        </div>
<!--        <CheckboxFilter-->
<!--            v-if="tagsFilters.length > 0"-->
<!--            :fieldsData="tagsFilters"-->
<!--            :title="$trans.tags"-->
<!--            prefix="tag_"-->
<!--            category="tags"-->
<!--            @filterUpdate="handleFilterUpdate($event)"-->
<!--        />-->
        <CheckboxFilter
            v-if="locationTagsFilters.length > 0"
            :fieldsData="locationTagsFilters"
            :title="$trans.location_tags"
            prefix="tag_"
            category="location_tags"
            @filterUpdate="handleFilterUpdate($event)"
        />
        <CheckboxFilter
            v-if="priceTagsFilters.length > 0"
            :fieldsData="priceTagsFilters"
            :title="$trans.price_tags"
            prefix="tag_"
            category="price_tags"
            @filterUpdate="handleFilterUpdate($event)"
        />
        <CheckboxFilter
            v-if="boardTagsFilters.length > 0"
            :fieldsData="boardTagsFilters"
            :title="$trans.board_tags"
            prefix="tag_"
            category="board_tags"
            @filterUpdate="handleFilterUpdate($event)"
        />
        <CheckboxFilter
            v-if="typeTagsFilters.length > 0"
            :fieldsData="typeTagsFilters"
            :title="$trans.type_tags"
            prefix="tag_"
            category="type_tags"
            @filterUpdate="handleFilterUpdate($event)"
        />
        <CheckboxFilter
            v-if="accommodationTagsFilters.length > 0"
            :fieldsData="accommodationTagsFilters"
            :title="$trans.accommodation_tags"
            prefix="tag_"
            category="accommodation_tags"
            @filterUpdate="handleFilterUpdate($event)"
        />
        <CheckboxFilter
            v-if="suitableTagsFilters.length > 0"
            :fieldsData="suitableTagsFilters"
            :title="$trans.suitable_tags"
            prefix="tag_"
            category="suitable_tags"
            @filterUpdate="handleFilterUpdate($event)"
        />
        <CheckboxFilter
            v-if="servicesTagsFilters.length > 0"
            :fieldsData="serviceseTagsFilters"
            :title="$trans.services_tags"
            prefix="tag_"
            category="services_tags"
            @filterUpdate="handleFilterUpdate($event)"
        />
      
        <CheckboxFilter
            v-if="amenitiesFilters.length > 0"
            :fieldsData="amenitiesFilters"
            :title="$trans.amenities"
            prefix="amenities_"
            category="amenities"
            @filterUpdate="handleFilterUpdate($event)"
        />
    </div>
</template>

<script>
import CheckboxFilter from "./CheckboxFilter.vue";
import StarsFilter from "./StarsFilter.vue";
import {getQueryStringParameters} from "@/components/common/helper";

export default {
    name: "Filters",
    components: {
        CheckboxFilter,
        StarsFilter
    },
    props: {
        init_filters: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            filters: {
                board: {},
                stars: {},
                tags: {},
                location_tags: {},
                price_tags: {},
                board_tags: {},
                type_tags: {},
                accommodation_tags: {},
                suitable_tags: {},
                services_tags: {}
            }
        };
    },
    methods: {
        setInitFilters(data) {
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const category = data[key]; // board: {}
                    
                    this.filters[key] = {}
                    for (const key2 in category) {
                        if (Object.hasOwnProperty.call(category, key2)) {
                            const filter = category[key2];                     
                            this.filters[key][key2] = !filter.checked ? 0 : 1
                        }
                    }           
                }
            }
        },
        handleFilterUpdate(data) {
            this.filters = Object.assign(this.filters, data);
            // this.$emit('filtersUpdate', this.generateQueryString())
        },
        mapFiltersData(data) {
            return Object.keys(data).map((key) => {
                return {
                    key: key,
                    value: !data[key]['checked'] ? 0 : 1,
                    label: data[key]['label'],
                    sort: data[key]['sort'] ? Number(data[key]['sort']) : 0,
                };
            }).sort((a, b) => a['sort'] - b['sort']);
        },
        generateQueryString(){
            const searchURL = new URL(window.location);
            let queryString = ''
            for (const category in this.filters) { 
                // let items = []
                let items = ''
                for (const key in this.filters[category]) { 
                    if (this.filters[category][key]) {
                        //  items.push(key)
                        if (items.length > 0) {
                            items += ',' + key
                        } else{
                            items += key
                        }
                        
                    }             
                }
                if (items.length > 0) {
                    // queryString += `${category}=${JSON.stringify(items)}&`
                    queryString += `${category}[]=${items}&`;
                    searchURL.searchParams.set(`${category}[]`, `${items}`);
                }
            }

            searchURL.searchParams.set('page', "1")

            window.history.pushState({}, '', searchURL);
            return queryString
        },
        sendFiltersQueryStringOnCheckboxChange(){
            // Select all checkboxes with the name 'settings' using querySelectorAll.
            var checkboxes = document.querySelectorAll(".fiters-container input[type=checkbox]");
            let self = this

            function onChange(e) {   
                self.$emit('filtersUpdate', self.generateQueryString())
            }

            // Use Array.forEach to add an event listener to each checkbox.
            checkboxes.forEach(function(checkbox) {
                // checkbox.removeEventListener('change', onChange);
                if (checkbox.getAttribute('listener') !== 'true') {
                    checkbox.addEventListener('change', onChange)   
                    checkbox.setAttribute('listener', 'true');
                }
            })
        },
        // clear(){
        //     this.setInitFilters(this.init_filters)
        // }
    },
    computed: {
        start_date() {
            let queryString = getQueryStringParameters();
            return queryString.start;
        },
        amenitiesFilters() {
            return this.mapFiltersData(this.init_filters.amenities ?? {});
        },
        tagsFilters() {
            return this.mapFiltersData(this.init_filters.tags ?? {});
        },
        locationTagsFilters() {
            return this.mapFiltersData(this.init_filters.location_tags ?? {});
        },
        priceTagsFilters() {
            return this.mapFiltersData(this.init_filters.price_tags ?? {});
        },
        boardTagsFilters() {
            return this.mapFiltersData(this.init_filters.board_tags ?? {});
        },
        typeTagsFilters() {
            return this.mapFiltersData(this.init_filters.type_tags ?? {});
        },
        accommodationTagsFilters() {
            return this.mapFiltersData(this.init_filters.accommodation_tags ?? {});
        },
        suitableTagsFilters() {
            return this.mapFiltersData(this.init_filters.suitable_tags ?? {});
        },
        servicesTagsFilters() {
            return this.mapFiltersData(this.init_filters.services_tags ?? {});
        },
        mealsFilters() {
            return this.mapFiltersData(this.init_filters.board ?? {});
        },
        starsFilters() {
            return this.mapFiltersData(this.init_filters.stars ?? {});
        },
        clonedFilters(){return Object.assign({}, this.filters) }
    },
    mounted(){
        this.setInitFilters(this.init_filters)
        this.sendFiltersQueryStringOnCheckboxChange()
    },
    updated(){
        this.sendFiltersQueryStringOnCheckboxChange()
    }
};
</script>

<style>
</style>