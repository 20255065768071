<template>
    <div class="d-flex">       
        <!-- Filters -->
        <div v-if="breakpoint === 'lg' || breakpoint === 'xl'">
            <div class="fiters-container">
                <!-- Search Component-->
<!--          <div v-show="start_date">-->
              <div>
                <Search 
                    :is_mobile="true"
                    :urls="urls"
                    :lang="lang"
                    :trans="trans"
                    :filters_query_string="filtersQueryString"
                    @loaded="handleLoaded"
                    @success="handleSuccess"
                    @formResponse="handleResponse"
                    @formResponseURL="handleFormResponseURL"
                ></Search>
                <div class="mt-3">
                    <HotelsMap
                        :init_hotels_data="hotels_results"
                        :urls="urls"
                        :key="mapsRerenderKey"
                    > 
                    </HotelsMap>
                </div>
              </div>
                <Filters 
                    :init_filters="filters"
                    @filtersUpdate="(val) => filtersQueryString = val"
                />
            </div>
        </div>
        <!-- Mobile Filters -->
        <div v-else>
             <div class="offcanvas offcanvas-start bg-white" tabindex="-1" id="filtersMenu" aria-labelledby="filtersMenuLabel">
                <div class="offcanvas-header">
                    <button id="filtersMenuClose" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div class="fiters-container">
                        <!-- Search Component-->
                      <div v-show="start_date">
                         <Search 
                            :is_mobile="true"
                            :urls="urls"
                            :lang="lang"
                            :trans="trans"
                            :filters_query_string="filtersQueryString"
                            @loaded="handleLoaded"
                            @success="handleSuccess"
                            @formResponse="handleResponse"
                            @formResponseURL="handleFormResponseURL"
                        ></Search>
                        <div class="mt-3">
                            <HotelsMap
                                :init_hotels_data="hotels_results"
                                :urls="urls"
                                :key="mapsRerenderKey"
                            > 
                            </HotelsMap>
                        </div>
                      </div>
                        <Filters 
                            :init_filters="filters"
                            @filtersUpdate="(val) => {
                                filtersQueryString = val
                            }"
                        />
                    </div>
                </div>
            </div>        
        
        </div>
        <transition-group name="fade" tag="div" class="flex-1 m-2 xs-m-0">
            <ResultsContainer v-show="loaded"
                :hotels_results="hotels_results"
                :hotels_results2="hotels_results2"
                :paging="paging"
                :urls="urls"
                :response_url="responseURL"
                :trans="trans"
                key="results"
            />
            <div v-if="!loaded" key="loader" class="d-flex justify-content-center bg-white h-100" style="padding-top: 30%">
                <div class="d-flex align-items-center flex-column">
                    <div class="logo purple-logo animate-flicker"></div>
                        <div class="mt-2" style="width: 50px; height: 50px;"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgba(241, 242, 243, 0); display: block;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <path d="M10 50A40 40 0 0 0 90 50A40 42.5 0 0 1 10 50" fill="#f6bb27" stroke="none">
                        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51.25;360 50 51.25"></animateTransform>
                        </path>
                        </svg></div>
                </div>
            </div>
        </transition-group>

    </div>
</template>

<script>
import Vue from 'vue'
import {getQueryStringParameters, isObject, useMediaQuery} from "@/components/common/helper"
import Search from "@/components/search/Index.vue";
import HotelsMap from "@/components/maps/MapCloseHotels.vue";
import Filters from "./filters/Index.vue";
import ResultsContainer from "./results_container/Index.vue";

export default {
    name: "HotelsResultsPage",
    components: {
        Search,
        HotelsMap,
        Filters,
        ResultsContainer
    },
    props: {
        init_data: {
            type: Object,
            required: true,
        },
        urls: {
            type: Object,
            required: true,
        },
        lang: {
            type: String,
            default: 'en'
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return { 
            hotels_results: {},
            hotels_results2: {},
            filters: {},
            paging: {},
            filtersQueryString: '', 
            responseURL: '', 
            isMobileFiltersVisible: false,
            breakpoint: 'lg',
            loaded: true,
            success: false,
        };
    },
    methods: {
        setResults(results = {}){
            // added the key as ID and convert to arrray of jsons
           this.hotels_results = Object.keys(results).map(key => {
               if (results[key]['spo_price'] === results[key]['reg_price']) {
                   results[key]['reg_price'] = null
               }
               return Object.assign(results[key], {id: key})
            });
        },
        setResults2(results = {}){
            // added the key as ID and convert to arrray of jsons
           this.hotels_results2 = Object.keys(results).map(key => {
               if (results[key]['spo_price'] === results[key]['reg_price']) {
                   results[key]['reg_price'] = null
               }
               return Object.assign(results[key], {id: key})
            });
        },
        setFilters(filters = {}){
           this.filters = filters;
        },
        setPaging(paging = {}){
           this.paging = paging;
        },
        handleResponse(val) {
            if (val && isObject(val)) {
                this.setResults(val.result ?? {})
                this.setFilters(val.filters ?? {})
                this.setPaging(val.paging ?? {})
            }
        },
        handleFormResponseURL(val) {
            this.responseURL = val 
        },
        handleLoaded(val) {
            this.loaded = val;
            this.hideFiltersMenu()
        },
        handleSuccess(val) {
            this.success = val;
        },
        hideFiltersMenu() {
            let filtersMenuCloseBtn = document.getElementById('filtersMenuClose')
            if (filtersMenuCloseBtn) {
                filtersMenuCloseBtn.click()
            }
        },
        useMediaQuery
    },
    computed: {
        mapsRerenderKey() {
            return Math.floor(Math.random() * Object.keys(this.hotels_results).length * 1000);
        },
        start_date() {
            let queryString = getQueryStringParameters();
            return queryString.start;
        }
    },
    mounted(){
        useMediaQuery((size) => this.breakpoint = size) 
    },
    created(){
        this.setResults(Object.assign({}, this.init_data.result))
        this.setResults2(Object.assign({}, this.init_data.result2))
        this.setFilters(Object.assign({}, this.init_data.filters))
        this.setPaging(Object.assign({}, this.init_data.paging))
     
        if (!Vue.prototype.$trans) {
            Vue.prototype.$trans = this.trans 
        } 
    }
};
</script>

