var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:_vm.providerRef,attrs:{"tag":"div","rules":_vm.rules,"vid":_vm.vid,"immediate":_vm.immediate},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('DatePicker',{ref:"calendar",staticClass:"custom-calendar",attrs:{"masks":_vm.masks,"step":1,"locale":_vm.locale,"first-day-of-week":2,"select-attribute":_vm.attribute,"drag-attribute":_vm.attribute,"attributes":_vm.attributes,"popover":{
            visibility: 'click',
            keepVisibleOnInput: false,
            placement: 'bottom',
        },"model-config":_vm.modelConfig},on:{"popoverWillShow":function($event){return _vm.handlePopoverWillShow()},"popoverDidHide":function($event){return _vm.handlePopoverDidHide()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var inputValue = ref.inputValue;
        var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({class:_vm.fieldClass,attrs:{"placeholder":_vm.placeholder},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }