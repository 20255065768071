<template>
    <form @submit.prevent="submitForm"
        accept-charset="UTF-8" 
        class="d-flex w-100 flex-wrap justify-content-center"
        novalidate=""
    >           
            <div class="p-2 flex-1 search-location-input">
                <AutocomleteInput
                    :endpoint="urls.api_autocomplete"
                    @destinationChange="handleDestinationChange"
                />
                <div v-if="!valid" class="text-warning fs-14">{{$trans.required_field ? $trans.required_field : 'Задължително поле'}}</div>
            </div>
      
            <div class="calendar-container p-2">
                <BookingDatepicker
                    :lang="lang"
                    :init_data="init_data"
                    @datepickerChange="handleDatepickerChange"
                />
            </div>
            <div class="guests-container p-2">
                <GuestsDropdown
                    @guestsChange="handleGuestsChange"
                />
            </div>
            <div class="button-container p-2">
                <!-- {{-- Submit Button --}} -->
                <button
                    class="btn btn-warning min-height-55 fs-24 hover-text-white hover-bg-primary text-primary fw-500 min-width-170"
                    type="submit"
                >
                    {{$trans.search}}
                </button>
            </div>
                                
    </form>
</template>

<script>
import formMixin from '@/mixins/formMixin';
// import Datepickers from './Datepickers';
import BookingDatepicker from './BookingDatepicker';
import AutocomleteInput from './AutocompleteInput.vue';
import GuestsDropdown from './GuestsDropdown.vue';

// import SearchInput from './SearchInput';
// import Filters from './Filters';

// import moment from 'moment';

export default {
    name: 'SearchForm',
    mixins: [
        formMixin
    ],
    components: {
      BookingDatepicker,
      AutocomleteInput,
      GuestsDropdown
    },
    props: {
        urls: {
            type: Object,
            required: true
        },
        lang: {
            type: String,
            default: 'en'
        },
        filters_query_string: {
            type: String,
            default: ''
        },
        init_data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            action: this.urls.form_action,
            formActionType: 'get',
            fields: {},
            valid: true
        }
    },
    methods: {
        handleDatepickerChange(data){
            for (const key in data) {
                if (data.hasOwnProperty.call(data, key)) {
                    this.fields[key] = data[key]
                    
                }
            }  
        },
        handleGuestsChange(data){
            for (const key in data) {
                if (data.hasOwnProperty.call(data, key)) {
                    this.fields[key] = data[key]
                    
                }
            }          
        },
        handleDestinationChange(data) {
            this.fields['destination'] = data.value
            this.fields['type'] = data.type
            this.fields['label'] = data.label
        },
        submitForm() {
            if (this.urls.api_autocomplete && !this.fields.destination) {
                this.valid = false

                return
            }
            
            // Genetare query string
            var queryString = '?'
        
            for (var key in this.fields) {
                if(key === 'rooms') {
                    // Adding rooms details
                    this.fields[key].forEach((room, i) => {
                        queryString += `room_${i}=${JSON.stringify(room)}&`
                    });
                    
                } else{
                    queryString += `${key}=${this.fields[key]}&`
                }
            }
            
            //Add the filters
            queryString += this.filters_query_string

            if(this.urls.form_action_async){
                this.action = this.urls.form_action_async + queryString;
                this.submit()
            } else {
                window.location.href = this.action + queryString;
            }        
        }
    },
    watch: {
        // Submit form on filters change
        filters_query_string: {
            handler(val){
                this.submitForm()
            }
        },
    },
}
</script>