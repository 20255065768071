<template>
    <ValidationProvider
        tag="div"
        :rules="rules"
        :immediate="immediate"
        :vid="vid"
        v-slot="v"
    >
        <div class="form-check">
            <input
                v-model="currentValue"
                type="checkbox"
                :name="name"
                :checked="value"
                :id="fieldID"
                :class="fieldClass"
                :disabled="disabled"
                :true-value="trueValue"
                :false-value="falseValue"
            />
            <label v-if="label" :class="labelClass" :for="fieldID">
                <span v-html="label"></span>
            </label>
            <div
                v-if="v.errors[0] || (serverErrors && serverErrors[name])"
                class="text-danger fs-13"
            >
                {{ v.errors[0] || serverErrors[name][0] }}
            </div>
        </div>
    </ValidationProvider>
</template>
             

<script>
/** 
 * Example:
    <Checkbox 
        v-model="fields.test_val"
        name="test_val"
        fieldID="test_val"
        rules="required"
        :label="$t('LABEL_TEXT')"    
        :trueValue="true"
        :falseValue="false"
        :serverErrors="errors ? errors : null"
    />
*/

import { ValidationProvider } from "vee-validate";

export default {
    name: "CheckboxField",
    components: {
        ValidationProvider,
    },
    props: {
        value: {
            type: [Boolean, Number],
            default: false,
        },
        rules: {
            type: [String, Object],
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        vid: {
            type: String,
            default: undefined,
        },
        immediate: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        serverErrors: {
            type: [String, Object],
            default: undefined,
        },
        fieldClass: {
            type: String,
            default: "form-check-input",
        },
        fieldID: {
            type: String,
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        labelClass: {
            type: String,
            default: "form-check-label",
        },
        trueValue: {
            type: [Number, Boolean],
            default: 1,
        },
        falseValue: {
            type: [Number, Boolean],
            default: 0,
        },
    },
    data() {
        return {
            currentValue: this.value !== null ? this.value : 0,
        };
    },
    watch: {
      value (val) {
        this.currentValue = val;
      },
      currentValue (val) {
        // allows us to use v-model on our input.
        // if (val === this.value) {
          this.$emit('input', val);         
        // }
      },
    },
};
</script>