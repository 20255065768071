let customization = {
    formBackgroundColor: '#ffffff',
    labelFontSize: '14px',
    labelColor: '#262a2e',
    inputBorderRadius: '3px',
    payButtonBorderColor: '#f6bb27',
    payButtonBackgroundColor: '#f6bb27',
    payButtonBorderColorHover: '#662d91',
    payButtonBorderColorFocus: '#662d91',
    payButtonBackgroundColorHover: '#662d91',
    payButtonBackgroundColorFocus: '#662d91',
    payButtonColor: '#662d91',
    payButtonColorHover: '#ffffff',
    payButtonColorFocus: '#ffffff',
    payButtonFontSize: '16px'
}


function makeGetInfo(data) {
    let str = "";
    for (let key in data) {
        if (str !== "") {
            str += "&";
        }
        str += key + ":" + encodeURIComponent(data[key]);
    }
    return str;
}


/**
 * 
 * @param {string} containerId - iframe container id 
 * @param {number} bookingPrice - The final amount
 * @param {string} bookingId - The reservation id
 * @param {string} bookingCurrency - The current currency.
 * @param {string} bookingLanguage - The current locale
 * @param {number} myposTest - Is this test request
 * @param {string} sid - myposSid
 * @param {string} walletNumber - myposSid
 * @param {string} url - mypos Url
 * @param {number} keyIndex - keyIndex
 * @param {string} urlNotify - myposUrlNotify
 * @param {string} absolutUri - absolutUri
 * @param {boolean} isSandbox - isSandbox
 */
export const initMyPos = (
    containerId,
    bookingPrice,
    bookingId,
    bookingCurrency,
    bookingLanguage,
    myposTest,
    sid,
    walletNumber,
    url,
    keyIndex,
    urlNotify,
    absolutUri,
    isSandbox
) => {

    if(myposTest === 1)
        bookingPrice = 0.2

    let paymentParams = {
        sid: sid,
        PaymentMethod: 1,
        walletNumber: walletNumber,
        ipcLanguage: bookingLanguage,
        amount: bookingPrice,
        currency: bookingCurrency,
        orderID: bookingId,
        urlNotify: urlNotify,
        // url: url,
        keyIndex: keyIndex,
        cartItems: [
            {
                article: 'Hotel',
                quantity: 1,
                price: bookingPrice,
                currency: bookingCurrency,
            },
        ]
    };

    // console.log(paymentParams);

    let callbackParams = {

        isSandbox: isSandbox,

        onMessageReceived: function (messages) {
            console.log(messages)
        },

        onSuccess: function () {
            setTimeout(function () {
                window.location.href = absolutUri + "&mypos_ok=1";
            }, 3000);
        },

        onError: function (messages) {
            console.log(messages)
            // setTimeout(function () {
            //     window.location.href = absolutUri + "&mypos_ok=2";
            // }, 3000);
        }
    };

    // console.log(callbackParams);

    MyPOSEmbedded.createPayment(
        containerId,
        paymentParams,
        callbackParams,
        customization

    );
}
