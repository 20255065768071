<template>
    <div class="mt-3 popular-filters">
        <div class="rounded bg-light px-2 py-3 text-gray-3">
            <span class="fw-bold px-2 fs-5">{{title}}</span>
            <div class="m-2 fs-5">
                <Checkbox v-for="item in fieldsData" :key="item.key"
                    v-model="fields[item.key]"
                    :value="fields[item.key]"
                    :name="`${prefix}${item.key}`"
                    :fieldID="`${prefix}${item.key}`"
                    :label="item.label"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Checkbox from "@/components/common/fields/CheckboxField.vue";

export default {
    name: "CheckboxFilter",
    components: {
        Checkbox
    },
    props: {
       fieldsData:{
           type: Array,
           required: true
       },
       title: {
           type: String,
           default: 'Filter'
       },
       category: {
           type: String,
           required: true
       },
        prefix: {
           type: String,
           default: ''
       }  
    },
    data() {
        return{
            //map the fieldsData array item to key:value object 
            fields: this.fieldsData.reduce((ac, {['key']:x,value}) => (ac[x] = value,ac), {})
        }
    },
    watch: {
        fields: {
            deep: true,
            handler(val){
                this.$emit('filterUpdate', Object.assign({}, {[this.category]: val}))
            }
        },
        fieldsData: {
            deep: true,
            handler(val){
                this.fields = val.reduce((ac, {['key']:x,value}) => (ac[x] = value,ac), {})
            }
        }
    }
};
</script>

<style>
</style>