/**
 * vanilla JS check for class
 *
 * @param {dom element (object)} el
 * @param {string} cls
 * @returns {bool}
 */
export const hasClass = (el, cls) => {
    return el.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}


/**
 * vanilla JS add class
 *
 * @param {object (dom element)} el
 * @param {string} cls
 * @returns {string (element's classes)}
 */
export const addClass = (el, cls) => {
    if (!hasClass(el, cls)) {
        return el.className += " " + cls;
    }
}

export const truncateText =  (text = '', stop) => {
    return text.slice(0, stop) + (stop < text.length ? '...' : '')
}

/**
 * vanilla JS set cookie
 *
 * @param {string} name
 * @param {*} value
 * @param {number} days
 */
export const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

/**
 * vanilla JS get cookie
 *
 * @param {string} name
 * @returns {*}
 */
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

/**
 * vanilla JS delete cookie
 *
 * @param {string} name
 */
export const eraseCookie = (name) => {
    document.cookie = name + '=; Max-Age=-99999999;';
}

/**
 * Parse response errors
 *
 * @param {Object, Json} error
 * @returns {Array, Object,}
 */
export const parseError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        // console.log(error.response.data.errors);
        if (error.response.status === 422) {

            return error.response.data.errors || {};

        } else if (error.response.status === 500) {
            
            return {
                request: 'Sorry, something went wrong, please contact our Customer support and provide the following code: ' + error.response.status
            }
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("LOG: error.request", error.request)
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log("LOG: error.message", error.message)
    }
}

/**
 * Try to parse input to json
 *
 * @param {String} text
 * @param {String} partial
 * @returns {bool}
 */
export const isContains = (text, partial) => {
    if (text !== null
        && partial !== null) {

        return text.toString().toLowerCase().indexOf(partial.toLowerCase()) != -1;
    }

    return false;
}

/**
 * Try to parse input to json
 *
 * @param {json} input
 * @returns {json, undefined}
 */
export const tryParse = (input) => {
    try {
        return JSON.parse(input);
    } catch (e) {
        return undefined;
    }
}

/**
 * check if a value is an object
 *
 * @param {Object} value
 * @returns {bool}
 */
export const isObject = (value) => {
    return value
        && typeof value === 'object'
        && value.constructor === Object;
}

/**
 * Search for string in the object's values
 * @param Object obj
 * @param String str
 * 
 * @return Array
*/
export const searchInObj = (obj, str) => {
    return Object.keys(obj).some(function (key) {
        if (typeof (obj[key]) === 'string') {
            return obj[key].toLowerCase().includes(str.toLowerCase());

        }
    })
}

/**
 * Filter by given string in the object's values/keys
 * @param Object obj
 * @param String filter
 * @param Boolean byKey
 *
 * @return Object
*/

export const filterObject = (obj, filter, byKey = false) => {
    if (byKey) {
        return Object.fromEntries(Object.entries(obj)
            .filter(([key, value]) => key === filter))
    }

    return Object.fromEntries(Object.entries(obj)
        .filter(([key, value]) => value === filter))
}


/**
 * Get the url's query params as JSON (ES6)
 *   
 * @param  String url
 * @return JSON
 *
 **/
export const getQueryStringParameters = (url = null) => {
    let query = (url && url.split("?").length > 0)
        ? query = url.split("?")[1]
        : window.location.search.substring(1);

    if (!(!!query)) {
        return null;
    }

    return (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
            let [key, value] = param.split('=');
            params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';

            return params;
        }, {});
}

export const appLocale = document.querySelector('html').getAttribute('lang') || 'en';

export const useMediaQuery = (callback) => {
    const screen = {
        xs: null,
        sm: window.matchMedia('(min-width: 576px)'),
        md: window.matchMedia('(min-width: 768px)'),
        lg: window.matchMedia('(min-width: 992px)'),
        xl: window.matchMedia('(min-width: 1200px)')
    }

    // add media query events
    for (let [scr, mq] of Object.entries(screen)) {
        if (mq) mq.addEventListener('change', mqHandler);
    }

    // first event
    mqHandler();

    // media query handler function
    function mqHandler() {
        let size = null;
        for (let [scr, mq] of Object.entries(screen)) {
            if (!mq || mq.matches) size = scr;
        }
        console.log(`JavaScript value: ${size}`);
        callback(size);
    }
}


/**
 * Smooth scroll to section
 *  
 * @return void
 *
 **/
export const smoothScroll = () => {
    let links = document.getElementsByClassName("goToSection")
 
    if (links){
        for (let i = 0; i < links.length; i++) {
            links[i].addEventListener('click', function () {
                let target = document.getElementById(this.getAttribute('data-scroll-to-id'));
                if (target) {
                    scroll({
                        top: target.offsetTop - 60,
                        behavior: "smooth"
                    });
                }            
            }); 
        };
    }
}