/**
 * Example:
    
    FormExample Component

    import formMixin from "../../../components-2/mixins/formMixin";
    export default {
        name: 'FormExample',
        mixins: [
            formMixin
        ],
        data() {
            return {
                action: 'https://jfdbank.com/en/my-jfd/some-form-action',   // set the action
                formActionType: 'post',                                     // set the type (by default is 'get') 
                fields: {                                                   // by default, you have the "page: 1" field which is used for the paginated requests.
                    field2: 'test',
                    field3: 34,
                    field4: false                                           // You can add fields directly from the component's template: <Input v-model="fields.first_name" ...
                },
            }
        }
    
    
    Events - the component which uses this mixin by default fire these events:
        <FormExample
            @loaded="handleLoaded"
            @success="handleSuccess"
            @formResponse="handleResponse"
            @error="handleError"
        />
     Note: hide/show the component only with v-show. 
           Not use v-if. This will re-render the component and the events will not work properly.
*/

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import axios from "axios";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },

    data() {
        return {
            action: '',
            formActionType: 'get',
            fields: {},
            errors: {},
            success: false,
            loaded: true,
            resetKey: 0
        }
    },

    watch: {
        // submit the form on page change
        currentPage: {
            handler: function () {
                this.submit()
            }
        },

        // reset the pagination on form fields change
        computedFields: {
            handler: function (after, before) {
                if (before['page'] && (after['page'] === before['page'])) {
                    this.fields.page = 1
                }
            },
            deep: true
        },
    },

    methods: {
        submit: function () {
            let formData = this.parseToFormData(this.fields);

            if (this.loaded) {
                this.loaded = false;
                this.$emit('loaded', this.loaded)
                this.success = false,
                    this.$emit('success', false)
                this.errors = {};

                axios[this.formActionType](this.action, formData).then(response => {
                    this.loaded = true;
                    this.$emit('loaded', this.loaded)
                    this.success = true;
                    this.$emit('success', true)

                    if (response.data.length !== undefined || response.data.length !== 0) {
                        this.$emit('formResponse', response.data)
                        this.$emit('formResponseURL', response.request.responseURL ? response.request.responseURL : '')

                        if (response.request.status === 201)
                            window.location.href = this.urls.home_url;

                    }

                }).catch(error => {
                    this.login_error = this.trans.invalid_user_or_pass;
                    this.reg_error = this.trans.user_already_reg;
                    this.loaded = true;
                    this.$emit('loaded', this.loaded)
                    this.$emit('error', error)
                    // Error
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        // console.log(error.response.data);

                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};

                        } else if (error.response.status === 500) {
                            this.errors.request = 'Sorry, something went wrong, please contact our Customer support and provide the following code: ' + error.response.status || {};
                            this.status = error.response.status || {};

                        }
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log("LOG: error.request", error.request)
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("LOG: error.message", error.message)
                    }
                });
            }
        },

        /**
         * Add query params to the GET route
         * @param {string} route
         * @param {object} params
         * @return {string}
        */
        generateGETRoute(route = this.action, params = this.fields) {
            route = `${route}?`;

            for (var key in params) {
                route += `${key}=${params[key]}&`
            }

            return route;
        },

        parseToFormData(fields) {
            let formData = new FormData();

            for (const field in fields) {
                if (fields.hasOwnProperty(field)) {
                    formData.append(field, fields[field]);
                }
            }

            return formData;
        },

        handleExportedFormFieldError(event) {
            if (event.errors !== null) {
                this.errors[event.name] = event.errors[0]
            } else {
                this.errors[event.name] = null
            }
        },

        resetForm() {
            this.fields = {}
            this.resetKey += 1;
        },

        /** 
        * add Honeypot input fields in the form request
        * @return void 
       */
        addHoneypotFields() {
            this.fields['jfd_name'] = document.getElementsByName("jfd_name") ? document.getElementsByName("jfd_name")[0].value : '';
            this.fields['jfd_time'] = document.getElementsByName("jfd_time") ? document.getElementsByName("jfd_time")[0].value : '';
        }
    },

    computed: {
        currentPage: function () {
            return this.fields.page || 1;
        },

        computedFields: function () {
            return Object.assign({}, this.fields);
        }
    },

}