<template>
  <ValidationProvider 
    tag="div"
    :ref="providerRef" 
    :rules="rules" 
    :vid="vid"
    v-slot="v" 
    :immediate="immediate">
      <input v-model="currentValue"
            :type="type"
            :name="name"
            :id="fieldID"
            :class="fieldClass"
            :readonly="readonly"
            :placeholder="placeholder"
      >
    <div v-if="v.errors[0] || serverErrors && serverErrors[name]" class="text-danger fs-13">
         {{ v.errors[0] || serverErrors[name][0] }}
    </div>
  </ValidationProvider>
</template>

<script>
/** 
 * Example:
  <Input
      v-model="fields.email"
      name="email"
      fieldClass="some-class"
      rules="required|email"
      :placeholder="$t('PHP_FORM_EMAIL')"
      :serverErrors="errors ? errors : null"
  />
*/


import { ValidationProvider } from 'vee-validate';

export default {
  name: 'InputField',
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    vid: {
      type: String,
      default: undefined
    },
    providerRef: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    serverErrors: {
      type: [String, Object],
      default: undefined 
    },
    fieldClass: {
      type: String,
      default: 'custom-input' 
    },
    fieldID: {
      type: String,
      default: undefined
    },
    immediate: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
  },
  data() {
        return {
            currentValue: this.value
        }
  },
  watch: {
    value (val) {
      this.currentValue = val;
    },
    currentValue (val) {
      // allows us to use v-model on our input.
      this.$emit('input', val);
    }
  }
};
</script>

