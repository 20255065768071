<template>
    <div>
         <!-- Desktop -->
        <div class="mobile-hidden">
            <ul class="nav nav-tabs" id="hotelsTabs" role="tablist">
                <li class="nav-item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="overview-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#overview" 
                        type="button" 
                        role="tab" 
                        aria-controls="overview" 
                        aria-selected="true"
                    >{{init_data.overview_tab.title}}</button>
                </li>
                <li v-if="details" class="nav-item" role="presentation">
                    <button 
                        class="nav-link active" 
                        id="rooms-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#rooms" 
                        type="button" 
                        role="tab" 
                        aria-controls="rooms" 
                        aria-selected="false"
                    >{{trans.rooms}}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="location-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#location" 
                        type="button" 
                        role="tab" 
                        aria-controls="location" 
                        aria-selected="false"
                    >{{init_data.location_tab.title}}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="amenities-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#amenities" 
                        type="button" 
                        role="tab" 
                        aria-controls="amenities" 
                        aria-selected="false"
                    >{{init_data.amenities_tab.title}}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="policies-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#policies" 
                        type="button" 
                        role="tab" 
                        aria-controls="policies" 
                        aria-selected="false"
                    >{{init_data.policies_tab.title}}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="concept-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#concept" 
                        type="button" 
                        role="tab" 
                        aria-controls="concept" 
                        aria-selected="false"
                    >{{init_data.concept_tab.title}}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="specialOffer-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#specialOffer" 
                        type="button" 
                        role="tab" 
                        aria-controls="specialOffer" 
                        aria-selected="false"
                    > <span class="text-warning">{{init_data.special_offers_tab.title}}</span></button>
                </li>
            </ul>
        </div>
        <!-- Mobile -->
        <div class="xs-d-block display-none sticky-top-50 bg-white">
            <ul class="scrolling-wrapper nav nav-tabs" id="hotelsTabs" role="tablist">
                <li class="nav-item item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="overview-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#overview" 
                        type="button" 
                        role="tab" 
                        aria-controls="overview" 
                        aria-selected="true"
                    >{{init_data.overview_tab.title}}</button>
                </li>
                <li v-if="details" class="nav-item item" role="presentation">
                    <button 
                        class="nav-link active" 
                        id="rooms-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#rooms" 
                        type="button" 
                        role="tab" 
                        aria-controls="rooms" 
                        aria-selected="false"
                    >{{trans.rooms}}</button>
                </li>
                <li class="nav-item item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="location-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#location" 
                        type="button" 
                        role="tab" 
                        aria-controls="location" 
                        aria-selected="false"
                    >{{init_data.location_tab.title}}</button>
                </li>
                <li class="nav-item item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="amenities-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#amenities" 
                        type="button" 
                        role="tab" 
                        aria-controls="amenities" 
                        aria-selected="false"
                    >{{init_data.amenities_tab.title}}</button>
                </li>
                <li class="nav-item item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="policies-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#policies" 
                        type="button" 
                        role="tab" 
                        aria-controls="policies" 
                        aria-selected="false"
                    >{{init_data.policies_tab.title}}</button>
                </li>
                <li class="nav-item item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="concept-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#concept" 
                        type="button" 
                        role="tab" 
                        aria-controls="concept" 
                        aria-selected="false"
                    >{{init_data.concept_tab.title}}</button>
                </li>
                <li class="nav-item item" role="presentation">
                    <button 
                        class="nav-link" 
                        id="specialOffer-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#specialOffer" 
                        type="button" 
                        role="tab" 
                        aria-controls="specialOffer" 
                        aria-selected="false"
                    > <span class="text-warning">{{init_data.special_offers_tab.title}}</span></button>
                </li>
            </ul>
        </div>
        <div class="tab-content" id="hotelsTabsContent">
            <div class="tab-pane fade" id="overview"  role="tabpanel" aria-labelledby="overview-tab">
                <div class="panel panel-default p-3">
                    <div>
                        <div class="w-100 d-flex flex-wrap align-items-center">
                            <div class="fs-4 fw-500 me-3">{{init_data.name}}</div>
                        </div>
                        <div>
                            <span class="me-2 text-decoration-underline">{{init_data.location}}</span>
                        </div>
                    </div>
                    <div class="mt-4">
                        <p v-html="init_data.overview_tab.full_info"></p>
<!--                        <p v-html="init_data.overview_tab.description"></p>-->
<!--                        <p v-html="init_data.overview_tab.rooms_description"></p>-->
<!--                        <p v-html="init_data.overview_tab.facilities_description"></p>-->
<!--                        <p v-html="init_data.overview_tab.distance_to_places_description"></p>-->
<!--                        <p v-html="init_data.overview_tab.additional_description"></p>-->
                    </div>
        
                </div>
            </div> 
            <div v-if="details" class="tab-pane fade show active" id="rooms"  role="tabpanel" aria-labelledby="rooms-tab">
                <h3 v-if="Object.keys(details.rooms).length > 0" class="fs-5">{{trans.select_accommodation_type}}</h3>
                <div v-else  class="px-2 py-5 text-center">
                    <h3 class="fs-5">{{trans.no_rooms_available_for_the_period ? trans.no_rooms_available_for_the_period : 'За избрания период няма налични стаи'}}</h3>
                    <a :href="urls.request_url + `?hotel=${query_params.destination}&check-in=${query_params.start}&check-out=${query_params.end}&room1=${query_params.room_0}&room2=${query_params.room_1}`" target="_blank">
                        <button class="btn btn-warning hover-text-white hover-bg-primary text-primary mt-3">
                        {{trans.send_inquiry ? trans.send_inquiry : 'Изпрати Запитване'}}
                        </button>
                    </a>
                </div>                
                <RoomsDetails 
                    :details="details"
                    :trans="trans"
                    :urls="urls"
                    @roomsUpdate="(val) => $emit('roomsUpdate', val)" 
                />
            </div>  
            <div class="tab-pane fade" id="location"  role="tabpanel" aria-labelledby="location-tab">
                <div class="panel panel-default p-3">
                    <SingleMap 
                        :lat="init_data.location_tab.latitude"
                        :lng="init_data.location_tab.longitude"
                    />
                    <div class="mt-2 fw-500 d-flex">
                        <div class="me-2"><img src="@/assets/images/vacantina_pin.png" alt=""></div>
                        <div>
                            <div>{{init_data.name}}</div> 
                            {{init_data.location}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="amenities"  role="tabpanel" aria-labelledby="location-tab">
                <div class="panel panel-default p-3">
                    <div><h4>{{trans.popular_amenities}}</h4></div>
                    <div class="d-flex flex-wrap">
                        <div v-for="(amenity, i) in init_data.amenities_tab.popular[0]" :key="i" class="p-2 white-space-nowrap d-flex align-items-center" style="width: 220px">
                            <i class="vac-icon box-30" v-bind:class="`${amenity.icon}`"></i> <span style="white-space: initial">{{amenity.label}}</span>
                        </div>
                       
                    </div>
                    <div class="mt-2"><h4>{{trans.all_amenities}}</h4></div>
                    <div class="d-flex flex-wrap">
                        <div v-for="(amenity, i) in init_data.amenities_tab.all[0]" :key="i" class="p-2 white-space-nowrap d-flex align-items-center" style="width: 220px">
                            <i class="vac-icon box-30 flex-shrink-0" v-bind:class="`${amenity.icon}`"></i> <span style="white-space: initial">{{amenity.label}}</span>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="tab-pane fade" id="policies"  role="tabpanel" aria-labelledby="location-tab">
                <div class="panel panel-default p-3">
                    <div>
                        <div class="fs-4 fw-500 me-3">{{init_data.name}}</div>
                    </div>
                    <p class="mt-4" v-html="general_rem"></p>
                    <p class="mt-4" v-html="children_rem"></p>
                    <p class="mt-4" v-html="children_ages"></p>
<!--                    <p class="mt-4" v-html="cancel_rem"></p>-->
                </div>
            </div>   
            <div class="tab-pane fade" id="concept"  role="tabpanel" aria-labelledby="location-tab">
                <div class="panel panel-default p-3">
                    <div>
                        <div class="fs-4 fw-500 me-3">{{init_data.name}}</div>
                        <div class="fs-5 fw-500">{{init_data.concept_tab.title}}</div>
                    </div>
                    <p class="mt-4">
                        {{init_data.concept_tab.description}}
                    </p>
                    <div v-for="(concept, i) in init_data.concept_tab.concept_files" :key="i" class="rounded bg-gray-2 p-2 mt-2">
                        <p><a :href="`${concept.file}`" target="_blank"><strong>{{concept.name}}</strong></a></p>
                    </div>
                </div>
            </div>    
            <div class="tab-pane fade" id="specialOffer"  role="tabpanel" aria-labelledby="location-tab">
                <div class="panel panel-default p-3">
                    <div v-for="(offer, i) in init_data.special_offers_tab.offers" :key="i" class="rounded bg-gray-2 p-3 mt-3">
                        <p><strong>{{offer.title}}</strong></p>
                        <p v-html="offer.description"></p>
                    </div>
                </div>
            </div>         
        </div> 
    </div>       
</template>

<script>

import SingleMap from "@/components/maps/SingleMap.vue";
import RoomsDetails from "./RoomsDetails.vue";
import {getQueryStringParameters} from "@/components/common/helper";

export default {
    name: "HoteTabs",
    components: {
        SingleMap,
        RoomsDetails
    },
    props: {
        init_data: {
            type: Object,
            required: true,
        },
        details: {
            type: Object,
            default: null,
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        },
        urls: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
           
        };
    },
    methods: {
       
    },

    computed: {
        query_params() {
            return getQueryStringParameters();
        },
        general_rem:function(){
          return this.init_data.policies_tab.general_remarks.replace(/\n/g, '<br />');
        },
        children_rem:function(){
          return this.init_data.policies_tab.child_policy.replace(/\n/g, '<br />');
        },
        children_ages:function(){
          return this.init_data.policies_tab.child_ages;
        },
        cancel_rem:function(){
          return this.init_data.policies_tab.cancel_policy.replace(/\n/g, '<br />');
        }
    }
};
</script>