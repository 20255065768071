// import Swiper JS
import Swiper, {Autoplay, Navigation, Pagination, Thumbs} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);

Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);

// var swiper = new Swiper(".top-offers-slider",
//     {
//         spaceBetween: 30,
//         speed: 600,
//         direction: "horizontal",
//         centeredSlides: true,
//         autoplay: {
//             delay: 2500,
//             disableOnInteraction: false
//         },
//         pagination: {
//             el: ".swiper-pagination",
//             clickable: true
//         }
//     }
// );

window.addEventListener("load", function () {
    initHomeSliders();
});

const initHomeSliders = () => {
    var headerSlider = new Swiper(".header-slider", {
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
    });

    let topOffersSlider = new Swiper(
        ".top-offers-slider",
        {
            // Optional parameters
            direction: "horizontal",
            loop: true,
            autoHeight: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            // loopFillGroupWithBlank: true,
            // autoplay: {
            //   delay: 5000,
            //   disableOnInteraction: false
            // },
            breakpoints: {
                1400: {
                    slidesPerView: 4,
                    slidesPerGroup: 1
                },
                1200: {
                    slidesPerView: 3,
                    slidesPerGroup: 1
                },
                992: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                },
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                },
                480: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                }
            },
            // Navigation arrows
            navigation: {
                nextEl: ".top-offers-button-next",
                prevEl: ".top-offers-button-prev"
            }
        }
    );

    let topHotelsSlider = new Swiper(
        ".top-hotels-slider",
        {
            // Optional parameters
            direction: "horizontal",
            loop: true,
            autoHeight: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            // loopFillGroupWithBlank: true,
            // autoplay: {
            //   delay: 5000,
            //   disableOnInteraction: false
            // },
            breakpoints: {
                1400: {
                    slidesPerView: 4,
                    slidesPerGroup: 1
                },
                1200: {
                    slidesPerView: 3,
                    slidesPerGroup: 1
                },
                992: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                },
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                },
                480: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                }
            },
            // Navigation arrows
            navigation: {
                nextEl: ".top-hotels-button-next",
                prevEl: ".top-hotels-button-prev"
            }
        }
    );

    let nearHotelsSlider = new Swiper(
        ".near-hotels-slider",
        {
            // Optional parameters
            direction: "horizontal",
            loop: true,
            autoHeight: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            // loopFillGroupWithBlank: true,
            // autoplay: {
            //   delay: 5000,
            //   disableOnInteraction: false
            // },
            breakpoints: {
                1400: {
                    slidesPerView: 3,
                    slidesPerGroup: 1
                },
                1200: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                },
                992: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                },
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                },
                480: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                }
            },
            // Navigation arrows
            navigation: {
                nextEl: ".top-hotels-button-next",
                prevEl: ".top-hotels-button-prev"
            }
        }
    );

    let hotelThumbsSlider = new Swiper(".hotel-thumbs-slider", {
        spaceBetween: 10,
        slidesPerView: 6,
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
            1400: {
                slidesPerView: 6
            },
            1200: {
                slidesPerView: 5
            },
            992: {
                slidesPerView: 4
            },
            767: {
                slidesPerView: 3
            },
            480: {
                slidesPerView: 2
            }
        },
        navigation: {
            nextEl: ".thumbs-button-next",
            prevEl: ".thumbs-button-prev",
        },
    });

    var hotelSlider = new Swiper(".hotel-slider", {
        spaceBetween: 10,
        navigation: {
            nextEl: ".hotel-button-next",
            prevEl: ".hotel-button-prev",
        },
        thumbs: {
            swiper: hotelThumbsSlider,
        },
    });

    var hotelSliderMobile = new Swiper(".hotel-slider-mobile", {
        direction: "horizontal",
        loop: true,
        autoHeight: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        navigation: {
            nextEl: ".hotel-button-next",
            prevEl: ".hotel-button-prev",
        },
    });

    let topDestinationsSlider = new Swiper(
        ".top-destinations-slider",
        {
            // Optional parameters
            direction: "horizontal",
            loop: true,
            autoHeight: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            // loopFillGroupWithBlank: true,
            // autoplay: {
            //   delay: 5000,
            //   disableOnInteraction: false
            // },
            breakpoints: {
                1400: {
                    slidesPerView: 4,
                    slidesPerGroup: 1
                },
                1200: {
                    slidesPerView: 3,
                    slidesPerGroup: 1
                },
                992: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                },
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                },
                480: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                }
            },
            // Navigation arrows
            navigation: {
                nextEl: ".top-destinations-button-next",
                prevEl: ".top-destinations-button-prev"
            }
        }
    );
}