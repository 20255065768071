<template>
    <div :class="containerClass">
        <!-- Circle -->
        <div v-if="type === 'circle'">
            <div class="ui active centered inline loader"></div>
            <div class="xl-ta-center xl-mt-15 animated fadeIn">
                Loading
            </div>
        </div>
        <!-- BAR -->
        <div v-if="type === 'bar'" class="lds-loader">
            <div></div><div></div><div></div>
        </div>
    </div>

    
</template>

<script>
export default {
    name: 'Loader',
    props: {
        type: {
            type: String,
            default: 'circle',
            validator: (val) => ['circle', 'bar'].includes(val),
        },
        containerClass: {
            type: String,
            default: 'xl-m-v-30 xl-ta-center text grey'
        } 
    }
}
</script>
