<template>
      <div class="d-flex">       
        <div class="flex-1 m-2 xs-m-0 min-width-0 xs-lh-12">
            <div class="bg-white p-3 xs-px-0 xs-pb-0 rounded">
                <div class="d-flex justify-content-between align-items-center xs-px-2">
                    <div>
                        <div class="w-100 d-flex flex-wrap align-items-center">
                            <div class="fs-4 xs-fs-18 fw-500 me-3">{{init_data.name}}</div>
                            <div class="d-inline-block text-warning fs-14">
                                <i v-for="star, i in init_data.stars" :key="i" class="fas fa-star me-1"></i>
                            </div>
                        </div>
                        <div>
                            <span class="me-2 text-decoration-underline">{{init_data.location}}</span>
                            <span class="text-secondary fs-14">
                                {{init_data.from_center_txt}}
                            </span>
                        </div>
                    </div>
                </div>
                <!-- Desktop Slider -->
                <div class="mobile-hidden">
                    <div class="swiper hotel-slider mt-3">
                        <div class="swiper-wrapper">
                            <div v-for="(img, i) in init_data.overview_tab.images" :key="i" class="swiper-slide rounded overflow-hidden">
                                <img :src="img"/>
                            </div>
                        </div>
                        <div class="d-block hotel-button-prev"><i class="fas fa-chevron-left text-white fs-2"></i></div>
                        <div class="d-block hotel-button-next"><i class="fas fa-chevron-right text-white fs-2"></i></div>
                    </div>
                    <div thumbsSlider="" class="swiper hotel-thumbs-slider">
                        <div class="swiper-wrapper">
                            <div v-for="(img, i) in init_data.overview_tab.images" :key="i" class="swiper-slide rounded overflow-hidden">
                                <img :src="img"/>
                            </div>
                        </div>
                        <div class="d-block thumbs-button-prev"><i class="fas fa-chevron-left text-white fs-2"></i></div>
                        <div class="d-block thumbs-button-next"><i class="fas fa-chevron-right text-white fs-2"></i></div>
                    </div>
                </div>
                <!-- Mobile Slider -->
                <div class="xs-d-block display-none px-2">
                    <div class="swiper hotel-slider-mobile mt-2">
                        <div class="swiper-wrapper">
                            <div v-for="(img, i) in init_data.overview_tab.images" :key="i" class="swiper-slide rounded overflow-hidden">
                                <img :src="img" class="w-100" style="height: 200px;"/>
                            </div>
                        </div>
                        <div class="d-block hotel-button-prev"><i class="fas fa-chevron-left text-white fs-2"></i></div>
                        <div class="d-block hotel-button-next"><i class="fas fa-chevron-right text-white fs-2"></i></div>
                    </div>
                </div>
                
            </div>

            <div v-if="init_data.cancel_date" class="text-center xs-fs-14 text-success">
                <strong class="">{{trans.free_cancellation_before}} </strong>
                <span>{{init_data.cancel_date}}</span>
            </div>
            <!-- Tabs -->
            <div class="bg-white p-3 xs-p-0 rounded xs-mt-0">
                <HotelTabs 
                    :init_data="init_data"
                    :details="details"
                    :trans="trans"
                    :urls="urls"
                    @roomsUpdate="(val) => this.rooms=val" 
                />         
            </div>
        </div>
        <!-- Order -->
        <Order
            :rooms="rooms"
            :urls="urls"
            :lang="lang"
            :details="details"
            :trans="trans"
            :init_data="init_data"
        />
        
    </div>
</template>

<script>
import Search from "@/components/search/Index.vue";
import HotelTabs from "./Tabs/Index.vue";
import Order from "./Order/Index.vue";
// import NearHotelsSlider from "./NearHotelsSlider.vue";

export default {
    name: "HotelPage",
    components: {
        Search,
        HotelTabs,
        Order
    },
    props: {
        init_data: {
            type: Object,
            required: true,
        },
        init_details: {
            type: Object,
            default: () => {
                return {}
            },
        },
        urls: {
            type: Object,
            required: true,
        },
        lang: {
            type: String,
            default: 'en'
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            rooms: null
        };
    },

    computed: {
        details(){
            // Get fisrst item from 'data.result' as 'details' (the data.result' is object of objects instead of array of objects) 
            if (Object.keys(this.init_details).length > 0 && this.init_details.hasOwnProperty('result')){
                return this.init_details.result[Object.keys(this.init_details.result)[0]]
            }

            return null
        }
    }
};
</script>


<style scoped>
@media(min-width:768px){
  .panel-heading{
    display:none;
  }  
  .panel{
    border:none;
    box-shadow:none;
  }
  .panel-collapse{
    height:auto;
  }

  .panel-collapse.collapse{
        display:block;
    }
  
}

@media(max-width:767px){
  .tab-content .tab-pane{
       display:block;
    }    
  
   .nav-tabs{
      display:none;
  }
  .panel-title a{
    display:block;
  }
  .panel{
    margin:0;
    box-shadow:none;
    border-radius:0;
     margin-top:-2px;
  }
  .tab-pane:first-child .panel{
    border-radius:5px 5px 0 0;
  }
    
  

  .tab-pane:last-child .panel{
        border-radius:0 0 5px 5px;
    }
      
  }

</style>