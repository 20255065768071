<template>
  <div>
    <div class="accordion" id="accordionRooms">
        <div v-for="(room, key) in details.rooms" :key="key" class="accordion-item mt-3 xs-mt-1">
            <h2 class="accordion-header" :id="key">
                <button class="accordion-button text-white bg-primary" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse_' + key" aria-expanded="true" :aria-controls="'collapse_' + key">
                    <div class="d-flex flex-wrap">
                        <i class="fa fa-users pe-3"></i>
                        {{trans.choose_room}} {{key.replace('room_', '')}}:
                        <div>
                            <span class="ms-2 white-space-nowrap">{{room.adults}} {{trans.adults}}</span>
                            <span v-if="room.children" class="ms-2 white-space-nowrap">{{room.children}} {{trans.children}}</span>
                        </div>
                    </div>
                </button>
            </h2>
            <div :id="'collapse_' + key" class="accordion-collapse collapse" :class="{'show': key === 'room_1'}" :aria-labelledby="key" data-bs-parent="#accordionRooms">
                <div class="accordion-body xs-p-0">
                    <!-- Variation -->
                    <div v-for="(category, c_key) in room.categories" :key="c_key" class="d-xl-flex w-100" :class="c_key !== '0' ? 'mt-5 xs-mt-3': 'mt-3 xs-mt-0'">
                        <div class="room-category-container bg-gray-2 p-3 xs-pb-0 rounded mx-auto">
                            <div class="text-primary lh-sm">
                                <strong>{{category.name}}</strong><br/>
                                {{category.sub_category}}
                            </div>
                            <!--Desktop Amenities -->
                            <div class="mobile-hidden">
                            <div class="mt-3 fs-14">
                                <span v-for="(amenity, a_key) in category.amenities[0]" :key="a_key" class="white-space-nowrap d-flex align-items-center mt-1">
                                    {{amenity.label}}
                                    <!-- <i class="fas fa-utensils fs-12" :class="amenity.icon"></i> -->
                                    <i class="vac-icon box-30 scale-6" :class="`${amenity.icon}`"></i>
                                </span>
                            </div>
                            </div>
                            
                            <!--Mobile Amenities -->
                            <div class="xs-d-block display-none">
                            <div class="mt-2 fs-14">
                                <a class="panel-collapse collapsed d-flex align-items-center text-back" data-bs-toggle="collapse" :href="'#collapse' + c_key" role="button" aria-expanded="false" :aria-controls="'collapse' + c_key">
                                    <span>{{ trans.amenities ? trans.amenities : 'Amenities' }}</span> <i class="fa fa-angle-down arrow ms-1" aria-hidden="true"></i>
                                </a>
                                <div class="collapse" :id="'collapse' + c_key">
                                    <span v-for="(amenity, a_key) in category.amenities[0]" :key="a_key" class="white-space-nowrap d-flex align-items-center">
                                    {{amenity.label}}
                                        <!-- <i class="fas fa-utensils fs-12" :class="amenity.icon"></i> -->
                                        <i class="vac-icon box-30 scale-6" :class="`${amenity.icon}`"></i>
                                    </span>
                                </div>  
                            </div>
                            </div>
                            
                            <div class="mt-3 xs-mt-1 fs-14">
                                <i class="fas fa-circle" :class="category.status[0].value === 1 ? 'text-success-2' : 'text-warning'"></i>
                                <strong class="ms-1">{{category.status[0].label}}</strong>
                            </div>
                        </div>

                        <div class="mt-3 xs-mt-0 flex-1 ms-xl-3 mt-xl-0">
                            <!-- Item -->
                            <div v-for="(type, t_key) in category.types" :key="t_key" class="d-flex flex-column flex-sm-row  justify-content-between bg-gray-2 rounded xs-pb-2" :class="t_key !== '0' ? 'mt-2 xs-mt-0 xs-pt-3' : null">
                                <div class="flex-1 d-flex flex-column flex-xl-row align-items-center justify-content-between p-2">
                                    <div>
                                        <i class="fas fa-bed mx-2" :class="type.icon"></i>
                                        <span>{{type.name}}</span>
                                    </div>
                                    <span class="rounded-pill bg-success-2 text-white fs-14 px-2 mx-2">{{details.pay_only_pct_deposit}}</span>
                                    <div>
                                        <span v-if="type.reg_price !== type.spo_price" class="text-decoration-line-through mx-1">{{type.reg_price}}{{details.currency}}</span>
                                        <strong class="fs-4 mx-1"
                                                :class="type.reg_price !== type.spo_price ? 'text-danger' : 'text-black'"
                                        >
                                            {{type.spo_price}}{{details.currency}}
                                        </strong>
                                    </div>
                                </div>
                                <button
                                    class="justify-self-end btn min-width-120 goToSection max-width-220 mx-auto"
                                    data-scroll-to-id="roomsOrder"
                                    :class="checkIfSelected(type.rate_code, key) ? 'btn-primary' : 'btn-warning hover-text-white hover-bg-primary text-primary'"
                                    @click="selectRoom({id: key, ...room}, category, type)"
                                >
                                    <!-- {{isSelected(room.id, type.rate_code) ? 'Selected' : 'Select Room'}} -->
                                    {{checkIfSelected(type.rate_code, key) ? trans.selected : trans.choose_room}}
                                    <!-- {{rooms[room.id] && rooms[room.id]['rate_code'] === type.rate_code ? 'Selected' : 'Select Room'}} -->
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
    name: "RoomsDetails",
    props: {
        details: {
            type: Object,
            default: () => {
                return {}
            }
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        },
        urls: {
            type: Object,
            required: true,
        }
    },
    data() {
       return {
           rooms: {}
       }
    },
    methods: {
       selectRoom(room = {}, category = {}, type = {}) {      
            if (room !== {} && category !== {} && type !== {}){
                    let currentRoom = {
                        id: room.id,
                        adults: room.adults,
                        children: room.children,
                        children_ages: room.children_ages,
                        name: `${category.name}(${category.sub_category}) - ${type.name}`,
                        reg_price: type.reg_price,
                        spo_price: type.spo_price,
                        rate_code: type.rate_code,
                        currency: this.details.currency,
                        status: category.status,
                        reg_eur_price: type.reg_eur_price,
                        spo_eur_price: type.spo_eur_price,
                    }  

                    if (this.rooms[room.id] && this.rooms[room.id]['rate_code'] === currentRoom.rate_code) {
                        Vue.delete(this.rooms, room.id)
                        this.$emit('roomsUpdate', this.rooms)
                        
                        return
                    }

                    Vue.set(this.rooms, room.id, currentRoom)
                    this.$emit('roomsUpdate', this.rooms)
            }   
       },
       checkIfSelected(rate_code, room_key) {
           return this.selectedCodes[room_key] === rate_code
       }
    },
    computed: {
        selectedCodes() {
            let codes = {}
            Object.keys(this.rooms).map(key => {
                    codes[key] = this.rooms[key].rate_code
                    
                    return
                }
            )

            return codes
        }
    }
}
</script>

<style>
    .accordion-button::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    .room-category-container{
        width: 180px;
    }
    @media only screen and (max-width: 1200px) {
        .room-category-container{
               width: 100%;
        }
    }
</style>