import Vue from 'vue'

import "bootstrap";
import '@/assets/scss/app.scss';
import '@/assets/js/index.js';

require('./v-validation.js');
import axios from "axios";
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

Vue.config.productionTip = false

Vue.component('map-close-hotels', require('./components/maps/MapCloseHotels.vue').default);
Vue.component('search', require('./components/search/Index.vue').default);
Vue.component('hotels_results_page', require('./pages/HotelsResultsPage/Index.vue').default);
Vue.component('hotel_page', require('./pages/HotelPage/Index.vue').default);
Vue.component('hotel_payment_page', require('./pages/HotelPaymentPage/Index.vue').default);
Vue.component('footer_subscription', require('./components/footer_subscription/Index.vue').default);
Vue.component('register_login', require('./pages/RegisterLogin/Index.vue').default);
Vue.component('contact_us', require('./pages/ContactUs/Index.vue').default);

new Vue({
  el: '#vue-app'
})
// new Vue({
//   render: h => h(App),
// }).$mount('#app')

