<template>
      <div>
        <div class="bg-white p-3 rounded mt-3 d-flex align-items-center justify-content-center" style="min-height: 450px"> 
            <div class="m-auto max-width-600">
                <p>
                    {{trans.payment_sucessfull_txt}}
                </p>       
                <div class="mt-3 text-center">
                        <a  href="/"
                            class="btn btn-warning hover-bg-primary btn-lg text-white fw-500 w-100 max-width-250"
                            target="_blank"
                        >
                            OK
                        </a>
                </div>
            </div>
        </div>
      </div>
</template>

<script>
export default {
      props: {
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },

}
</script>

<style>

</style>