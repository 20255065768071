<template>
    <div class="position-relative">
        <div class="box-guests d-flex align-items-center" id="dropdownMenuOffset" data-bs-toggle="dropdown" aria-expanded="false" data-bs-offset="0,20">
            <i class="fa fa-users p-3 text-primary"></i>
            <div class="lh-sm guests-results">
                <div class="text-gray-3">{{$trans.guests}}</div>
                <div class="text-primary fw-500">{{guests}} {{$trans.guests}}, {{rooms.length}} {{rooms.length > 1 ? $trans.rooms : $trans.room}}</div>
            </div>
            <i class="fas fa-sort-down p-2 cursor-pointer"></i>
        </div>
        <div class="dropdown-menu animated box-guests-dropdown px-4 text-secondary user-select-none" aria-labelledby="dropdownMenuOffset">
            <div @click="($event) => $event.stopPropagation()">
                <div class="text-center py-2 fs-5">{{$trans.please_choose}}</div>
                <!-- Room -->
                <div v-for="(room, index) in rooms" :key="index" class="w-100 max-width-200">
                    <div class="d-flex align-items-center justify-content-between border-top">
                        <div class="text-primary fs-5 fw-500 pt-1">{{$trans.room}} {{index + 1}}</div>
                        <span @click="setRooms('dec', index)" class="px-2 cursor-pointer hover-text-warning">
                            <i class="fa fa-close"></i>
                        </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between py-2 lh-1">
                        <div class="">{{$trans.adults}}</div>
                        <div class="d-flex align-items-center fs-2 text-center">
                            <span @click="setAdults('dec', index)" class="px-2 cursor-pointer hover-text-warning">-</span>
                            <span class="px-2 text-primary">{{room.adults}}</span>
                            <span @click="setAdults('inc', index)" class="px-2 cursor-pointer hover-text-warning">+</span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between py-2 lh-1">
                        <div class="">{{$trans.children}} <br/><span class="fs-7">0-17</span></div>
                        <div class="d-flex align-items-center fs-2 text-center">
                            <span @click="setChildren('dec', index)" class="px-2 cursor-pointer hover-text-warning">-</span>
                            <span class="px-2 text-primary">{{room.children.length}}</span>
                            <span @click="setChildren('inc', index)" class="px-2 cursor-pointer hover-text-warning">+</span>
                        </div>
                    </div>
                    <div v-for="(child, indexChildren) in room.children" :key="indexChildren" class="d-flex align-items-center justify-content-between py-2">
                        <div class="me-1 lh-1">{{$trans.age_of_child}}</div>
                        <div class="d-flex align-items-center fs-2 text-center">
                            <input type="text" class="custom-input max-width-100 ml-2" v-model="child.age"/>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between py-2 border-top border-bottom">
                    <div class="text-primary fs-5 fw-500" @click="setRooms('inc')">{{$trans.add_room}}</div>
                </div>
            </div>    
            <div class="py-3 text-center">
                <span
                    class="btn btn-warning min-height-55 fs-24 hover-bg-primary text-white min-width-170 mx-2"
                >
                    {{$trans.apply}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {getQueryStringParameters, isContains} from '@/components/common/helper'

export default {
     name: 'GuestsRooms',
     props: {
        initRooms: {
            type: Array,
            default: () => [
                {
                    adults: 2,
                    children: [],
                }
            ]
        }
     },
     data() {
         return {
            rooms: this.initRooms
         }
     },
     methods:{
        setAdults(action, roomIndex){
            if (action === 'dec' && this.rooms[roomIndex].adults > 1) {
                this.rooms[roomIndex].adults--;
            } else if(action === 'inc'){
                this.rooms[roomIndex].adults++;
            }
        },
        setRooms(action, roomIndex=null){
            if (action === 'dec' && this.rooms.length > 1 && roomIndex !== null) {
                this.rooms.splice(roomIndex, 1);
            } else if(action === 'inc'){
                this.rooms.push(
                    {
                        adults: 2,
                        children: [],
                    }
                );
            }
        },
        setChildren(action, roomIndex){
            if (action === 'dec' && this.rooms[roomIndex].children.length > 0) {
                this.rooms[roomIndex].children.pop()
            } else if(action === 'inc'){
                this.rooms[roomIndex].children.push({ age: 5 })
            }
        },
        fillWithQueryString(){
            let queryString = getQueryStringParameters();
            if (queryString) {
                let rooms = []          
                for (const key in queryString) {
                    if (isContains(key, 'room')) {
                        const item = JSON.parse(queryString[key]);
                        rooms.push(item)
                    }
                }
                if (rooms.length > 0) {
                    this.rooms = rooms
                }
            }
        }
     },
     computed:{
        guests(){
            let result = 0
            this.rooms.map(room => result += room.adults + room.children.length)
            return result
        }
     },
     updated(){
        this.$emit('guestsChange', {
            rooms: this.rooms
        })
     },
     mounted(){
        this.fillWithQueryString()

        this.$emit('guestsChange', {
            rooms: this.rooms
        })
     }
}
</script>

<style>

</style>