<template>
    <div class="map-component-container">                                        <!-- Button trigger modal -->
        <button type="button" class="btn btn-primary min-height-55 fs-24 hover-text-warning text-white w-100" data-bs-toggle="modal" data-bs-target="#hotelsMapModal">
            {{$trans.view_on_map}}
        </button>

        <!-- Modal -->
        <div class="modal fade" id="hotelsMapModal" tabindex="-1" aria-labelledby="hotelsMapModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">    
                    <button type="button" class="btn-close rounded-circle shadow-sm" data-bs-dismiss="modal" aria-label="Close"></button>                
                    <div class="modal-body p-0">
                        <div class="h-100">
                            <!-- Hotels List -->
                            <div id="mapCardsContainer">
                                <form class="" onkeydown="return event.key != 'Enter';">
                                    <div class="alert alert-danger" role="alert" v-show="error">
                                        {{ error }}
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <div class="row">
                                                <div class="col">
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        placeholder="Enter your address"
                                                        v-model="address"
                                                        ref="autocomplete"
                                                    />
                                                </div>
                                                <div class="col-auto">
                                                    <span
                                                        class="btn btn-info"
                                                        @click="getCurrentLocation"
                                                        ><i
                                                            class="
                                                                fas
                                                                fa-map-marker-alt
                                                                fs-4
                                                                text-white
                                                            "
                                                        ></i
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <!-- Cards -->
                                <div class="">
                                    <div 
                                        v-for="hotel in visibleHotels"
                                        :key="hotel.id"
                                        @mouseover="showInfoWindow(hotel.id)"
                                        @mouseout="showInfoWindow(hotel.id)"
                                        :class="{ active: activeIndex === hotel.id }"
                                        class="d-flex m-2 align-items-stretch shadow-sm border rounded"
                                    >
                                        <div class="col-md-4 p-2"> 
                                            <div class="w-100 h-100" 
                                                :style="`background: url(${hotel.cover_image ? hotel.cover_image : ''}) center center; background-size: cover;`">
                                            </div>
                                        </div>
                                        <div class="col-md-8 p-2">
                                            <div class="title lh-sm text-capitalize fw-bold">{{ hotel.hotel_name }}</div>
                                            <div class="d-flex align-items-center mt-2 text-warning">
                                                <i v-for="star, i in hotel.stars" :key="i" class="fas fa-star me-1"></i>
                                            </div>
                                            <!-- <div class="mt-2 lh-sm grey text card-description">Part of a hotel's dscription. Lorem ipsum dolor sit amet consectetur adipisicing elit...</div> -->
                                            <div class="mt-3 d-flex align-items-center justify-content-between">
                                                <strong class="me-2 fs-4">{{hotel.spo_price}}{{hotel.currency}}</strong>  
                                                <a :href="urls.hotel_page_url.replace('None', hotel.hotel_slug)" 
                                                    class="btn btn-warning btn-lg text-white white-space-nowrap hover-text-white hover-bg-primary"
                                                    target="_blank"
                                                >
                                                    View
                                                </a>
                                            </div>                                                      
                                        </div>                 
                                    </div>                                        
                                </div>
                            </div>
                            <!-- Map -->
                            <div id="mapContainer" ref="map"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import image from "../../assets/images/vacantina_pin.png"

export default {
    name: "map-close-hotels",
    props: {
      init_hotels_data: {
        type: Array,
        default: () => {
            return []
        }
      },
      get_hotels_url: {
        type: String,
        // required: true
      },
      media_url: {
        type: String,
        default: 'https://vacantina-static.s3.amazonaws.com/mediafiles/'
      },
      urls: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            map: null,
            bounds: null,
            visibleHotels: [],
            address: "",
            error: "",
            spinner: false,
            apiKey: "AIzaSyDRhAQcwvojvYChps6X8080Cu2HfGREwVg",
            lat: 0,
            lng: 0,
            type: "",
            radius: "",
            places: [],
            markers: [],
            activeIndex: -1,
            hotels_data: []
        };
    },
    methods: {
        initMap() {
            this.map = new google.maps.Map(this.$refs["map"], {
                zoom: 14,
                center: new google.maps.LatLng(this.lat, this.lng),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                      {
                        "featureType": "poi.business",
                        "elementType": "all",
                        "stylers": [
                          {
                              "visibility": "off"
                          }
                        ]
                      }
                  ]
            });

            // this.bounds = this.map.getBounds();
        },

        getCurrentLocation() {
            this.spinner = true;

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.lat = position.coords.latitude;
                        this.lng = position.coords.longitude;

                        this.getAddressFromCoords(
                            position.coords.latitude,
                            position.coords.longitude
                        );
                    },
                    (error) => {
                        this.error =
                            "Locator is unable to find your address. Please type your address manually.";
                        this.spinner = false;
                        console.log(error.message);
                    }
                );
            } else {
                this.error = error.message;
                this.spinner = false;
                console.log("Your browser does not support geolocation API ");
            }
        },

        getAddressFromCoords(lat, long) {
            axios
                .get(
                    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                        lat +
                        "," +
                        long +
                        "&key=" +
                        this.apiKey
                )
                .then((response) => {
                    if (response.data.error_message) {
                        this.error = response.data.error_message;
                        console.log(response.data.error_message);
                    } else {
                        this.address =
                            response.data.results[0].formatted_address;
                        // console.log(response.data.results[0].formatted_address);
                    }
                    this.spinner = false;
                })
                .catch((error) => {
                    this.error = error.message;
                    this.spinner = false;
                    console.log(error.message);
                });
        },
        
        showInfoWindow(id) {
            this.activeIndex = id;
            // new google.maps.event.trigger(this.markers[index], "click");
            let marker = this.markers.filter(m => m.hotel_id === id)[0];
            
            this.displayToggleBounce(marker)
        },

        markersCreate() {
        //    this.markers = []
          const infoWindow = new google.maps.InfoWindow();

            for (let i = 0; i < this.visibleHotels.length; i++) {
                let hotel = this.visibleHotels[i]
                // Check if lat/lng
                if (!hotel.map_location.lat || !hotel.map_location.lon) {
                  console.log(`Missing coordiates for hotel ${hotel.hotel_name}`, hotel); 
                  continue
                }

                
                if(this.markers.some(marker => marker.hotel_id === hotel.id)){
                //    console.log(`Duplicate: ${hotel.hotel_name}`); 
                   continue    
                }

                let imageStyle = `background: url(${hotel.cover_image ? hotel.cover_image : ''}) center center; background-size: cover`;
                let url = this.urls.hotel_page_url.replace('None', hotel.hotel_slug)

                const lat = Number(hotel.map_location.lat);
                const lng = Number(hotel.map_location.lon);
                

                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(lat, lng),
                    icon: image,
                    animation: null, 
                    map: this.map,
                    draggable:true,
                    hotel_id: hotel.id,
                    url: url,
                });

                this.markers.push(marker);
                
                google.maps.event.addListener(marker, "mouseover", () => {
                    infoWindow.setContent(
                        `<div class="d-flex align-items-stretch shadow-sm border rounded" style='min-width:200px'>
                            <div class="col-md-4 p-2"> 
                                <div class="w-100 h-100" 
                                    style="${imageStyle}">
                                </div>
                            </div>
                            <div class="col-md-8 p-2">
                                <div class="title lh-sm text-capitalize fw-bold">${hotel.hotel_name }</div>
                                <div class="d-flex align-items-center mt-2 text-warning">
                                    ${(function fun() {
                                        let stars = ''
                                        for (let index = 0; index < hotel.stars; index++) {
                                            stars += "<i class='fas fa-star me-1'></i>"
                                        }
                                        return stars
                                    })()}
                                </div>
                                <span class="mt-4 p-2 bg-primary text-white d-inline-block rounded">${hotel.spo_price} ${hotel.currency}</span>                                                   
                            </div>                 
                        </div>`
                    );

                    infoWindow.open(this.map, marker);
                });

                google.maps.event.addListener(marker, "mouseout", () => {
                    infoWindow.close(this.map, marker);
                });
                
                google.maps.event.addListener(marker, "click", () => {
                    // window.location.href = marker.url;
                    window.open(marker.url, '_blank');
                    // let a= document.createElement('a');
                    // a.target= '_blank';
                    // a.href= marker.url;
                    // a.click();
                });
            }
        },

        displayToggleBounce(marker) {  
            if (marker.getAnimation() !== null) {  
                marker.setAnimation(null);  
            } else {  
                marker.setAnimation(google.maps.Animation.BOUNCE);  
            }  
        },
        
        initLocationDropdownAutocomplete() {
            let autocomplete = new google.maps.places.Autocomplete(this.$refs["autocomplete"], {
                bounds: new google.maps.LatLngBounds(
                    new google.maps.LatLng(42.7249925, 25.4833039)
                ),
            });

            autocomplete.addListener('place_changed', () => {
                let place = autocomplete.getPlace()
                this.lat = place.geometry.location.lat() 
                this.lng = place.geometry.location.lng()
            })
        },

        /* Append the modal to the bottom on the body (bootstrap issus)*/
        appendModalToBody() {
            let hotelsMapModal = document.getElementById('hotelsMapModal')
            hotelsMapModal.addEventListener('shown.bs.modal', function () {
                if (document.querySelector('body > [id=hotelsMapModal]')) {
                    document.body.replaceChild(this, document.querySelector('body > [id=hotelsMapModal]'));
                    
                } else {
                    document.body.appendChild(this);
                }
            });
        },     

        // Get the places in a given radius from the current position
        isPointInRadius(checkPoint, centerPoint, km) {
          var ky = 40000 / 360;
          var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
          var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
          var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;

          return Math.sqrt(dx * dx + dy * dy) <= km;
        },

        isPointInMapBounds(p){
            var ne = this.bounds.getNorthEast();
            var sw = this.bounds.getSouthWest();
            // console.log('lat: ', p.lat, 'lng: ', p.lng);

            // in case longitude 180 is inside the box
            let isLngInRange;

            if (ne.lng() < sw.lng()) {
                isLngInRange = p.lng >= sw.lng() || p.lng <= ne.lng()
            } else
                isLngInRange = p.lng >= sw.lng() && p.lng <= ne.lng()

            return p.lat >= sw.lat()  &&  p.lat <= ne.lat()  &&  isLngInRange
        },

        getHotelsData(){
            // Make ajax request with params: this.bounds and get hotels data
            this.visibleHotels = this.hotels_data.filter((hotel) => {
                return this.isPointInMapBounds({
                      lat: Number(hotel.map_location.lat), 
                      lng: Number(hotel.map_location.lon)
                    })
            })
            // this.visibleHotels = []
            // let keys = this.hotels_data.filter((hotel) => {
            //     return this.isPointInMapBounds({
            //           lat: Number(hotel.map_location.lat), 
            //           lng: Number(hotel.map_location.lon)
            //         })
            // })

            // keys.forEach(key => {
            //     this.visibleHotels.push(this.hotels_data[key])
            // });

            this.markersCreate()
        },

        // For testing - get all hotels
        initHotelsData(){
            axios.get(this.get_hotels_url)
                .then((response) => {
                    if (response.data.error_message) {
                        this.error = response.data.error_message;
                        console.log(response.data.error_message);
                    } else {
                        console.log(response.data);
                        this.hotels_data = response.data
                        
                    }
                    this.spinner = false;
                })
                .catch((error) => {
                    this.error = error.message;
                    this.spinner = false;
                    console.log(error.message);
                });
        }
    },

    mounted() {
        // this.initHotelsData()
        this.hotels_data = this.init_hotels_data
        this.initLocationDropdownAutocomplete()
        this.appendModalToBody()

        
        if (this.hotels_data.length > 0) {
            // use coordinates of the first hotel
            this.lat = this.hotels_data[0].map_location.lat; 
            this.lng = this.hotels_data[0].map_location.lon; 
        } else {
            // set Golden Sands' coordinates
            this.lat = 43.285553460794745; 
            this.lng = 28.040516301706486;
        }

        this.initMap()
        // this.getHotelsData()
        this.bounds = this.map.getBounds();

        let self = this
        let zoomChangeBoundsListener = google.maps.event.addListener(this.map,'idle', function (event) {  
    
            // console.log(self.map.getBounds());

            self.bounds = self.map.getBounds();
            console.log('idele');

            self.getHotelsData()
            
            

            // var ne = bounds.getNorthEast();
            // var sw = bounds.getSouthWest();
            // console.log(ne, '', sw);

            // var viewportPoints = [
            //     ne, new google.maps.LatLng(ne.lat(), sw.lng()),
            //     sw, new google.maps.LatLng(sw.lat(), ne.lng()), ne
            // ];

            // var viewportBox = new google.maps.Polyline({
            //     path: viewportPoints,
            //     strokeColor: '#FF0000',
            //     strokeOpacity: 1.0,
            //     strokeWeight: 4 
            // });
            // viewportBox.setMap(self.map);

            // viewportBox.setPath(viewportPoints);
            // TODO: remove all listeners on unmount
            // google.maps.event.removeListener(zoomChangeBoundsListener);
        });
    },

    watch: {
        // Update map on coordinates change
        lat: function() {
            this.map.setCenter(new google.maps.LatLng(this.lat,this.lng))
        },

        // init_hotels_data: {
        //     handler: function(newValue) {
        //         this.hotels_data = newValue
        //     },
        //     deep: true
        // }
    },
    beforeDestroy() {
        // alert('Unmount')
        // let hotelsMapModal = document.getElementById('hotelsMapModal')
        // document.body.removeChild(hotelsMapModal);
        
       
    }
};
</script>


<style>



.pac-icon {
    display: none;
}

/* .pac-item {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
} */

.pac-item:hover {
    background-color: #ececec;
}

.pac-item-query {
    font-size: 16px;
}

.pac-container {
    z-index: 1060 !important;
}

/* Info Window */
.gm-style .gm-style-iw {
    padding: 0 !important;
    /* margin: 0 !important; */
    max-width: 350px !important;
}

.gm-style-iw > button {
  display: none !important;
}

.gm-style-iw-d {
    overflow: hidden !important;
}

/* Modal */
#hotelsMapModal .modal-dialog{
     max-width: inherit;
     width: 90%;
     height: 90%;
}

#hotelsMapModal .modal-content{
     height: 100%;
}

#hotelsMapModal .btn-close{
    position: absolute;
    right: -20px;
    top: -20px;
    z-index: 1060;
    background-color: white;
    padding: 10px;
    opacity: 1;
}

/* Cards */
#mapCardsContainer {
    position: absolute;
    overflow: hidden;
    overflow-y: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 8px;
    padding-top: 0;
    width: 380px;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1001;
    -webkit-transition: -webkit-transform ease .2s;
    transition: transform ease .2s;
    background: #f9f9f9;
    -webkit-box-shadow: 0 0 24px rgba(0,0,0,0.15);
    box-shadow: 0 0 24px rgba(0,0,0,0.15);
}

#mapCardsContainer .hotels-list-container{
    height: 100%; 
    overflow: hidden; 
    overflow-y: auto;
}

#mapCardsContainer .title {
    color: #0071c2
}

#mapCardsContainer .active {
    background: #e9f0fa !important;
}

#mapCardsContainer  .active .title {
    color: #febb02;
    /* color: rgb(255, 0, 212); */


}

/* Map */
#mapContainer{
    float: right;
    width: calc(100% - 380px);
    height: 100%;
}

.grey.text{
    color: #777;
}


</style>