<template>
    <div class="flex-1 m-2 xs-mx-0">
        <div class="d-flex justify-content-between align-items-end">
            <h1 class="text-gray-3 px-2 fs-5">{{ trans.result }}: {{ result_info }}</h1>
        </div>
        <div class="d-flex justify-content-between align-items-end">
<!--            <h1 class="text-gray-3 px-2 fs-2">{{ trans.results }}</h1>-->
                <span class="fs-5 d-inline-block d-lg-none mx-2 cursor-pointer" data-bs-toggle="offcanvas" data-bs-target="#filtersMenu" aria-controls="filtersMenu" style="top: 200px">
                   <i class="fas fa-filter"></i>
                </span>
            <div class="px-2 fs-5 d-flex align-items-center">
                <i v-if="breakpoint === 'lg' || breakpoint === 'xl'" class="fas fa-bars mx-2 cursor-pointer" @click="changeGrid(1)"></i>
                <i v-if="breakpoint === 'lg' || breakpoint === 'xl'" class="fas fa-border-all mx-2 cursor-pointer" @click="changeGrid(2)"></i>
                <div class="input-group">
                    <!-- <label class="input-group-text text-secondary" for=""><i class="fas fa-sort"></i></label> -->
                    <select v-model="sortBy" @change="sortByChange($event)" class="form-select" aria-label="Sort by">
                        <option value="" selected disabled hidden>{{ trans.sorting_by }}</option>
                        <option value="price">{{ trans.price_lbl }} ({{ trans.lowest_first }})</option>
                        <option value="-price">{{ trans.price_lbl }} ({{ trans.highest_first }})</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap">
            <div
                v-for="(hotel, i) in sortedResults"
                :key="i"
                class="p-2 xs-px-0"
                style="transition: all 0.5s ease"
                :class="gridCols === 1 || breakpoint === 'sm' || breakpoint === 'xs' ? 'w-100' : 'w-50'"
            >
                <div
                    class="bg-light d-flex align-items-center h-100 mx-auto rounded position-relative overflow-hidden"
                    :class="gridCols === 2 || breakpoint === 'sm' || breakpoint === 'xs' ? 'flex-column' : null"
                >
                    <div class="w-100 flex-1 hotels-card-image" :class="gridCols === 1 ? 'h-100' : null" :style="`background-image: url(${hotel.cover_image})`">
                        <a :href="urls.hotel_page_url.replace('None/', `${hotel.hotel_slug}?${setQueryString(hotel.id)}`)" class="w-100 h-100 d-block">
                            <div v-if="hotel.promo_lbl" class="d-flex align-items-center mt-4">
                                <div class="bg-white py-2 px-3 text-gray-3 fs-5 rounded-end">
                                    {{ hotel.promo_lbl }}
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="d-flex flex-1 flex-column p-2 w-100 xs-lh-12">
                        <div class="w-100 d-flex flex-wrap align-items-center px-2">
                            <div class="fs-4 xs-fs-18 fw-500 me-3">
                                <a :href="urls.hotel_page_url.replace('None/', `${hotel.hotel_slug}?${setQueryString(hotel.id)}`)">
                                    {{ hotel.hotel_name }}
                                </a>
                            </div>
                            <div class="d-inline-block text-warning fs-14">
                                <i v-for="(star, i) in hotel.stars" :key="i" class="fas fa-star me-1"></i>
                            </div>
                        </div>
                        <div class="w-100 d-flex justify-content-between">
                            <div class="p-2 xs-py-0 d-flex flex-column justify-content-between">
                                <div>
                                    <span class="me-2 text-decoration-underline">{{ hotel.location }}</span>
                                    <div class="text-secondary fs-14">
                                        {{ hotel.from_center_txt }}
                                    </div>
                                </div>
                                <div>
                                    <strong class="text-success-2 xs-fs-13">{{ hotel.pay_only_pct_deposit }}</strong>
                                </div>
                            </div>
                            <div v-if="hotel.spo_price > 0">
                                <div class="p-2 xs-py-0 text-end lh-sm">
                                    <span class="fs-14 white-space-nowrap">{{ hotel.lbl_price_from }}</span>
                                    <div>
                                        <span v-if="hotel.reg_price" class="text-decoration-line-through pe-2">{{ hotel.reg_price }}{{ hotel.currency }}</span>
                                        <span class="fs-2 xs-fs-18 text-danger fw-500">{{ hotel.spo_price }}{{ hotel.currency }}</span>
                                    </div>
                                    <div class="text-secondary xs-fs-13">{{ hotel.lbl_guests }}, {{ hotel.lbl_nights }}</div>
                                </div>
                            </div>
                        </div>
                        <!-- Desktop -->
                        <div class="mobile-hidden">
                            <div class="w-100 d-flex flex-wrap justify-content-between align-items-end">
                                <div class="d-flex flex-wrap flex-2 min-width-1_2 p-2 fs-14 text-secondary">
                                    <div v-for="item in hotel.hotel_amenities" :key="item.service_code" class="w-100 pe-2 mt-2 white-space-nowrap d-flex">
                                        <i class="vac-icon box-30 scale-6 flex-shrink-0" v-bind:class="`${item.pictogram}`" style="margin-top: -8px"></i>
                                        <span class="white-space-initial lh-1">{{ item.name }}</span>
                                    </div>
                                </div>
                                <div class="flex-1 p-2 text-end">
                                    <a
                                        :href="urls.hotel_page_url.replace('None/', `${hotel.hotel_slug}?${setQueryString(hotel.id)}`)"
                                        class="btn btn-warning btn-lg text-white white-space-nowrap hover-text-white hover-bg-primary"
                                        target="_blank"
                                    >
                                        {{ hotel.submit_btn_lbl }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Mobile -->
                        <div class="xs-d-block display-none">
                            <div class="px-2">
                                <a class="panel-collapse collapsed fs-14 d-flex align-items-center text-secondary" data-bs-toggle="collapse" :href="'#collapse' + i" role="button" aria-expanded="false" :aria-controls="'collapse' + i">
                                    <span>{{ trans.amenities ? trans.amenities : 'Amenities' }}</span> <i class="fa fa-angle-down arrow ms-1" aria-hidden="true"></i>
                                </a>
                                <div class="collapse" :id="'collapse' + i">
                                    <div class="">
                                        <div class="d-flex flex-wrap flex-2 min-width-1_2 py-2 fs-14 text-secondary">
                                            <div v-for="item in hotel.hotel_amenities" :key="item.service_code" class="w-100 pe-2 mt-1 white-space-nowrap d-flex">
                                                <i class="vac-icon box-30 scale-6 flex-shrink-0" v-bind:class="`${item.pictogram}`" style="margin-top: -8px"></i>
                                                <span class="white-space-initial lh-1">{{ item.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <div class="px-2 text-end">
                                <a
                                    :href="urls.hotel_page_url.replace('None/', `${hotel.hotel_slug}?${setQueryString(hotel.id)}`)"
                                    class="btn btn-warning text-white white-space-nowrap hover-text-white hover-bg-primary"
                                    target="_blank"
                                >
                                    {{ hotel.submit_btn_lbl }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <nav style="margin-top: 1.25rem">
                <ul class="pagination justify-content-center" style="overflow: hidden">
                    <li class="page-item" v-bind:class="[paging['active_page'] === 1 ? 'disabled' : '']">
                        <a class="page-link" :href="setCurrentPage(paging['active_page'] - 1)" aria-label="Previous">
                            <span aria-hidden="true" style="font-size: 1.25rem">&laquo;</span>
                        </a>
                    </li>
                    <!--            <li v-for="index in paging['pages']" :key="index" class="page-item" v-bind:class="[index === paging['active_page'] ? 'active' : '']">-->
                    <li v-for="index in paging['vue_range']" :key="index" class="page-item" v-bind:class="[index === paging['active_page'] ? 'active' : '']">
                        <a :href="setCurrentPage(index)" class="page-link">
                            <span style="font-size: 1.25rem">{{ index }}</span>
                        </a>
                    </li>
                    <li class="page-item" v-bind:class="[paging['active_page'] === paging['pages'] ? 'disabled' : '']">
                        <a class="page-link" :href="setCurrentPage(paging['active_page'] + 1)" aria-label="Next">
                            <span aria-hidden="true" style="font-size: 1.25rem">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>

        </div>



        <div v-if="sortedResults2.length > 0" class="d-flex flex-wrap">
            <h3 class="d-flex flex-wrap text-gray-3">{{ trans.alt_offers }}</h3>
            <div
                v-for="(hotel, i) in sortedResults2"
                :key="i"
                class="p-2 xs-px-0"
                style="transition: all 0.5s ease"
                :class="gridCols === 1 || breakpoint === 'sm' || breakpoint === 'xs' ? 'w-100' : 'w-50'"
            >
                <div
                    class="bg-light d-flex align-items-center h-100 mx-auto rounded position-relative overflow-hidden"
                    :class="gridCols === 2 || breakpoint === 'sm' || breakpoint === 'xs' ? 'flex-column' : null"
                >
                    <div class="w-100 flex-1 hotels-card-image" :class="gridCols === 1 ? 'h-100' : null" :style="`background-image: url(${hotel.cover_image})`">
                        <a :href="urls.hotel_page_url.replace('None/', `${hotel.hotel_slug}?${setQueryString(hotel.id)}`)" class="w-100 h-100 d-block">
                            <div v-if="hotel.promo_lbl" class="d-flex align-items-center mt-4">
                                <div class="bg-white py-2 px-3 text-gray-3 fs-5 rounded-end">
                                    {{ hotel.promo_lbl }}
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="d-flex flex-1 flex-column p-2 w-100 xs-lh-12">
                        <div class="w-100 d-flex flex-wrap align-items-center px-2">
                            <div class="fs-4 xs-fs-18 fw-500 me-3">
                                <a :href="urls.hotel_page_url.replace('None/', `${hotel.hotel_slug}?${setQueryString(hotel.id)}`)">
                                    {{ hotel.hotel_name }}
                                </a>
                            </div>
                            <div class="d-inline-block text-warning fs-14">
                                <i v-for="(star, i) in hotel.stars" :key="i" class="fas fa-star me-1"></i>
                            </div>
                        </div>
                        <div class="w-100 d-flex justify-content-between">
                            <div class="p-2 xs-py-0 d-flex flex-column justify-content-between">
                                <div>
                                    <span class="me-2 text-decoration-underline">{{ hotel.location }}</span>
                                    <div class="text-secondary fs-14">
                                        {{ hotel.from_center_txt }}
                                    </div>
                                </div>
                                <div>
                                    <strong class="text-success-2 xs-fs-13">{{ hotel.pay_only_pct_deposit }}</strong>
                                </div>
                            </div>
                            <div v-if="hotel.spo_price > 0">
                                <div class="p-2 xs-py-0 text-end lh-sm">
                                    <span class="fs-14 white-space-nowrap">{{ hotel.lbl_price_from }}</span>
                                    <div>
                                        <span v-if="hotel.reg_price" class="text-decoration-line-through pe-2">{{ hotel.reg_price }}{{ hotel.currency }}</span>
                                        <span class="fs-2 xs-fs-18 text-danger fw-500">{{ hotel.spo_price }}{{ hotel.currency }}</span>
                                    </div>
                                    <div class="text-secondary xs-fs-13">{{ hotel.lbl_guests }}, {{ hotel.lbl_nights }}</div>
                                </div>
                            </div>
                        </div>
                        <!-- Desktop -->
                        <div class="mobile-hidden">
                            <div class="w-100 d-flex flex-wrap justify-content-between align-items-end">
                                <div class="d-flex flex-wrap flex-2 min-width-1_2 p-2 fs-14 text-secondary">
                                    <div v-for="item in hotel.hotel_amenities" :key="item.service_code" class="w-100 pe-2 mt-2 white-space-nowrap d-flex">
                                        <i class="vac-icon box-30 scale-6 flex-shrink-0" v-bind:class="`${item.pictogram}`" style="margin-top: -8px"></i>
                                        <span class="white-space-initial lh-1">{{ item.name }}</span>
                                    </div>
                                </div>
                                <div class="flex-1 p-2 text-end">
                                    <a
                                        :href="urls.hotel_page_url.replace('None/', `${hotel.hotel_slug}?${setQueryString(hotel.id)}`)"
                                        class="btn btn-warning btn-lg text-white white-space-nowrap hover-text-white hover-bg-primary"
                                        target="_blank"
                                    >
                                        {{ hotel.submit_btn_lbl }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Mobile -->
                        <div class="xs-d-block display-none">
                            <div class="px-2">
                                <a class="panel-collapse collapsed fs-14 d-flex align-items-center text-secondary" data-bs-toggle="collapse" :href="'#collapse' + i" role="button" aria-expanded="false" :aria-controls="'collapse' + i">
                                    <span>{{ trans.amenities ? trans.amenities : 'Amenities' }}</span> <i class="fa fa-angle-down arrow ms-1" aria-hidden="true"></i>
                                </a>
                                <div class="collapse" :id="'collapse' + i">
                                    <div class="">
                                        <div class="d-flex flex-wrap flex-2 min-width-1_2 py-2 fs-14 text-secondary">
                                            <div v-for="item in hotel.hotel_amenities" :key="item.service_code" class="w-100 pe-2 mt-1 white-space-nowrap d-flex">
                                                <i class="vac-icon box-30 scale-6 flex-shrink-0" v-bind:class="`${item.pictogram}`" style="margin-top: -8px"></i>
                                                <span class="white-space-initial lh-1">{{ item.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="px-2 text-end">
                                <a
                                    :href="urls.hotel_page_url.replace('None/', `${hotel.hotel_slug}?${setQueryString(hotel.id)}`)"
                                    class="btn btn-warning text-white white-space-nowrap hover-text-white hover-bg-primary"
                                    target="_blank"
                                >
                                    {{ hotel.submit_btn_lbl }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p>
    </div>
</template>

<script>
import {getQueryStringParameters, useMediaQuery} from "@/components/common/helper";

export default {
    name: "HotelsResultsContainer",
    components: {},
    props: {
        hotels_results: {
            type: Array,
            required: true,
        },
        hotels_results2: {
            type: Array,
            required: true,
        },
        paging: {
            type: [Array, Object],
            required: true,
        },
        urls: {
            type: Object,
            required: true,
        },
        response_url: {
            type: String,
            default: "",
        },
        trans: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            gridCols: 1,
            breakpoint: "lg",
            sortBy: "",
        };
    },
    methods: {
        sortByChange(event) {
            const searchURL = new URL(window.location);
            searchURL.searchParams.set("sort", event.target.value);
            searchURL.searchParams.set("page", "1");

            window.history.pushState({}, '', searchURL);
            window.location.reload();
        },
        changeGrid(cols) {
            this.gridCols = cols;
        },
        setQueryString(hotelId) {
            let url = location;

            if (this.response_url && this.response_url !== "") {
                url = new URL(this.response_url);
            }

            var searchParams = new URLSearchParams(url.search);
            searchParams.set("destination", hotelId);
            searchParams.set("type", "hotel");
            searchParams.set("step", "1");

            return searchParams.toString();
        },
        setCurrentPage(page) {
            let url = location;

            if (this.response_url && this.response_url !== "") {
                url = new URL(this.response_url);
            }

            var searchParams = new URLSearchParams(url.search);
            searchParams.set("page", page);

            var rq_address = url.origin + url.pathname + "?" + searchParams.toString();
            rq_address = rq_address.replace("hotel-search-async", "hotel-search");

            return rq_address;
        },
        pageChangeHandler(selectedPage) {
            this.currentPage = selectedPage;
        },
        currentUrl() {
            return this.urls.full_path;
        },
    },
    computed: {
        sortedResults() {
                      console.log(this.hotels_results);
            return this.hotels_results;

        },
        sortedResults2() {
           console.log(this.hotels_results2);
            return this.hotels_results2;
        },
        result_info() {
            let queryString = getQueryStringParameters();

            if(queryString.start) {
              let start_date = new Date(queryString.start)
              let start_month = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(start_date);
              let start_day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(start_date);

              let end_date = new Date(queryString.end)
              let end_month = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(end_date);
              let end_day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(end_date);

              return `${start_day}.${start_month} - ${end_day}.${end_month}`;
            } else {
              return "";
            }
        },
    },
    mounted() {
        useMediaQuery((size) => (this.breakpoint = size));
    },
};
</script>
